import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { getDiscountPrice, getDiscountPrice2 } from "../../helpers/product";
import Rating from "./sub-components/ProductRating";
import CreditProductConfirmModal from "./CreditProductConfirmModal";
import PointTransferResultModal from "./PointTransferResultModal";
import { setPointTransferResultShow } from "../../store/slices/user-slice";
import { useTranslation } from "react-i18next";

const CreditProductGridListSingle = ({
    product,
    currency,
    spaceBottomClass,
}) => {
    const { t } = useTranslation();
    const [modalShow, setModalShow] = useState(false);
    const [resultModalShow, setResultModalShow] = useState(false);
    const dispatch = useDispatch();
    const { point_transfer_result, point_transfer_result_show } = useSelector(
        (state) => state.user
    );

    const onClickProduct = (e) => {
        e.preventDefault();
        setModalShow(true);
    };

    useEffect(() => {
        if (point_transfer_result_show) {
            setResultModalShow(true);
        }
    }, [point_transfer_result_show]);

    return (
        <Fragment>
            <div className={clsx("product-wrap", spaceBottomClass)}>
                <a onClick={onClickProduct} href="!#">
                    <img
                        src={product.thumbnail}
                        alt={product.id}
                        style={{ width: "100%" }}
                    />
                    {/* <div className="credit-product-container">
                        <span className="credit-product-desc">
                            HKD {product.to_amount} Credit
                        </span>
                    </div> */}
                </a>
                <div className="product-content text-center">
                    <div className="product-price">
                        <a onClick={onClickProduct} href="!#">
                            <span>{product.from_amount} Beans</span>
                        </a>
                    </div>
                </div>
            </div>

            {/* confirm modal */}
            <CreditProductConfirmModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                product={product}
                currency={currency}
            />

            <PointTransferResultModal
                show={resultModalShow}
                onHide={() => {
                    setResultModalShow(false);
                    dispatch(setPointTransferResultShow(false));
                }}
                pointFromBefore={parseFloat(
                    point_transfer_result.prev_from_balance
                )}
                pointToBefore={parseFloat(
                    point_transfer_result.prev_to_balance
                )}
                pointFromAfter={parseFloat(
                    point_transfer_result.current_from_balance
                )}
                pointToAfter={parseFloat(
                    point_transfer_result.current_to_balance
                )}
            />
        </Fragment>
    );
};

CreditProductGridListSingle.propTypes = {
    currency: PropTypes.shape({}),
    product: PropTypes.shape({}),
    spaceBottomClass: PropTypes.string,
};

export default CreditProductGridListSingle;
