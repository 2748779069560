import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import { useMemo } from "react";
import { useSelector } from "react-redux";

const NavMenu = ({ navMenus, menuWhiteClass, sidebarMenu }) => {
    return (
        <div
            className={clsx(
                sidebarMenu
                    ? "sidebar-menu"
                    : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
            )}
        >
            <nav>
                <ul>
                    {navMenus.map((menu, mi) => (
                        <li key={`menu_${mi}`}>
                            <Link to={process.env.PUBLIC_URL + menu.to}>
                                {menu.label}
                                {(menu.groups?.length > 0 ||
                                    menu.sub_menus?.length > 0) &&
                                    (sidebarMenu ? (
                                        <span>
                                            <i className="fa fa-angle-right"></i>
                                        </span>
                                    ) : (
                                        <i className="fa fa-angle-down" />
                                    ))}
                            </Link>
                            {menu.groups && menu.groups.length > 0 && (
                                <ul className="mega-menu mega-menu-padding">
                                    {menu.groups.map((group, gi) => (
                                        <li key={`group_${gi}`}>
                                            <ul>
                                                <li className="mega-menu-title">
                                                    <Link
                                                        to={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            group.to
                                                        }
                                                    >
                                                        {group.label}
                                                    </Link>
                                                </li>

                                                {group.group_items.map(
                                                    (group_item, gii) => (
                                                        <li
                                                            key={`group_item_${gii}`}
                                                        >
                                                            <Link
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    group_item.to
                                                                }
                                                            >
                                                                {
                                                                    group_item.label
                                                                }
                                                            </Link>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </li>
                                    ))}
                                </ul>
                            )}

                            {menu.sub_menus && menu.sub_menus.length > 0 && (
                                <ul className="submenu">
                                    {menu.sub_menus.map((menu, mi) => (
                                        <li key={mi.toString()}>
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    menu.to
                                                }
                                            >
                                                {menu.label}
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    );
};

NavMenu.propTypes = {
    menuWhiteClass: PropTypes.string,
    sidebarMenu: PropTypes.bool,
};

export default NavMenu;
