import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export default function useLang() {
    const { i18n } = useTranslation();

    const langPostfix = useMemo(() => {
        switch (i18n.language) {
            case "zh-HK":
                return "_zh";
            case "zh-CN":
                return "_cn";
            default:
                return "_en";
        }
    }, [i18n.language]);

    const getLangValue = useCallback(
        (object, key) => {
            if (!object) return "";
            if (object[key + langPostfix]) return object[key + langPostfix];
            else if (object[key + "_en"]) {
                return object[key + "_en"];
            } else {
                return object[key];
            }
        },
        [langPostfix]
    );

    const getFlagValue = useCallback(
        (object, flag_en, flag_zh, flag_cn) => {
            if (!object) return "";
            switch (i18n.language) {
                case "zh-HK":
                    return object[flag_zh] || object[flag_en];
                case "zh-CN":
                    return object[flag_cn] || object[flag_en];
                default:
                    return object[flag_en];
            }
        },
        [i18n.language]
    );

    return {
        getLangValue,
        getFlagValue,
    };
}
