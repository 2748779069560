import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import clsx from "clsx";
import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";

const HeaderTop = ({ borderStyle }) => {
    const { t } = useTranslation();
    const currency = useSelector((state) => state.currency);
    const { token } = useSelector((state) => state.user);
    return (
        <div
            className={clsx(
                "header-top-wap",
                borderStyle === "fluid-border" && "border-bottom"
            )}
        >
            <LanguageCurrencyChanger currency={currency} />
            <div
                className="header-offer d-flex align-items-center justify-content-center"
                style={{ width: "calc(100% - 180px)", height: 54 }}
            >
                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay]}
                    loop
                    speed={1500}
                    height={54}
                    className="d-flex align-items-center justify-content-center"
                >
                    <SwiperSlide className="d-flex align-items-center justify-content-center" style={{height:'auto'}}>
                        <div className="text-center">
                            <Link
                                to={
                                    token
                                        ? process.env.PUBLIC_URL + "/my-account"
                                        : process.env.PUBLIC_URL +
                                          "/login-register"
                                }
                            >
                                <p>{t("top_bar__join_member")}</p>
                            </Link>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide className="d-flex align-items-center justify-content-center" style={{height:'auto'}}>
                        <p className="text-center">
                            {t("top_bar__local_free_shipping")}
                        </p>
                    </SwiperSlide>
                    {/* <SwiperSlide className="d-flex align-items-center justify-content-center" style={{height:'auto'}}>
                        <p className="text-center">
                            {t("top_bar__opening_hours")}
                        </p>
                    </SwiperSlide> */}
                </Swiper>
            </div>
            <div style={{ width: 80 }} />
        </div>
    );
};

HeaderTop.propTypes = {
    borderStyle: PropTypes.string,
};

export default HeaderTop;
