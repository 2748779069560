export const CLIENT_ID = 17;
export const CREDIT_POINT_TYPE = 2;
export const BEANS_POINT_TYPE = 1;
export const WEBSITE_SECTION_KEYS = {
    HOME_BANNER: 'home_banner',
    HOME_MORE_ABOUT_US: 'home_more_about_us',
    HOME_SUBSCRIBE_BLOCK: 'home_subscribe',
    HOME_CATEGORY: 'home_category',
    HOME_OUR_PURPOSE: 'home_our_purpose',
    ABOUT_US: 'about_us',
    OUR_STORY: 'our_story',
    OUR_COFFEE: 'our_coffee',
    OUR_SHOPS: 'our_shops',
    OUR_ACHIEVEMENTS: 'our_achievements',
    OUR_ACHIEVEMENTS_AWARD_IMAGES: 'our_achievements_award_images',
    OUR_SUSTAINABILITY_PRACTISES: 'our_sustainability_practises',
    STORE_LOCATIONS: 'store_locations',
    LIKE_A_CHAMP: 'like_a_champ',
    CORPORATE_CORNER: 'corporate_corner',
    CORPORATE_CORNER_THUMBNAIL: 'corporate_corner_thumbnail',
    SUBSCRIPTION: 'subscription',
    CLIENT_LOGO: 'client_logo',
    CAREERS: 'careers',
    DELIVERY: 'delivery',
    REFUND: 'refund',
    TERMS_OF_SERVICE: 'terms_of_service',
    PRIVACY_POLICY: 'privacy_policy',
    BENEFITS: 'benefits',
}
export const TOAST_POSITION = 'bottom-center';