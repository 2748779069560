import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const MobileSearch = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [keyword, setKeyword] = useState("");

    const onSearch = (e) => {
        e.preventDefault();
        navigate(process.env.PUBLIC_URL + `/shop?keyword=${keyword}`);
    };

    return (
        <div className="offcanvas-mobile-search-area">
            <form action="#">
                <input
                    type="search"
                    placeholder={t("nav_menu__search_placeholder")}
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                />
                <button type="submit" onClick={onSearch}>
                    <i className="fa fa-search" />
                </button>
            </form>
        </div>
    );
};

export default MobileSearch;
