import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setCurrency } from "../../../store/slices/currency-slice";
import { language_options } from "../../../i18n";

const LanguageCurrencyChanger = ({ currency }) => {
    const { i18n } = useTranslation();
    const { currency_options } = useSelector((state) => state.currency);
    const dispatch = useDispatch();
    const changeLanguageTrigger = (e) => {
        const languageCode = e.target.value;
        i18n.changeLanguage(languageCode);
    };

    const setCurrencyTrigger = (e) => {
        const currencyName = e.target.value;
        dispatch(setCurrency(currencyName));
    };
    return (
        <div className="language-currency-wrap">
            <div className="same-language-currency language-style">
                <span>
                    {
                        language_options.find(
                            (lo) => lo.value === i18n.resolvedLanguage
                        )?.label || language_options[0]?.label
                    }{" "}
                    <i className="fa fa-angle-down" />
                </span>
                <div className="lang-car-dropdown">
                    <ul>
                        {language_options.map((lo, loi) => (
                            <li key={`lang_${loi}`}>
                                <button
                                    value={lo.value}
                                    onClick={(e) => changeLanguageTrigger(e)}
                                >
                                    {lo.label}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            {/* <div className="same-language-currency use-style">
                <span>
                    {currency.currencyName} <i className="fa fa-angle-down" />
                </span>
                <div className="lang-car-dropdown">
                    <ul>
                        {currency_options.map((co, coi) => (
                            <li key={`currency_${coi}`}>
                                <button
                                    value={co.currencyName}
                                    onClick={(e) => setCurrencyTrigger(e)}
                                >
                                    {co.currencyName}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div> */}
            {/* <div className="same-language-currency">
                <p>Call Us 3965410</p>
            </div> */}
        </div>
    );
};

LanguageCurrencyChanger.propTypes = {
    currency: PropTypes.shape({}),
};

export default LanguageCurrencyChanger;
