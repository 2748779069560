import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import { useTranslation } from "react-i18next";

const FooterMain = ({
    backgroundColorClass,
    spaceTopClass,
    spaceBottomClass,
    spaceLeftClass,
    spaceRightClass,
    containerClass,
    extraFooterClass,
    sideMenu,
}) => {
    const { t } = useTranslation();
    return (
        <footer
            className={clsx(
                "footer-area",
                backgroundColorClass,
                spaceTopClass,
                spaceBottomClass,
                extraFooterClass,
                spaceLeftClass,
                spaceRightClass
            )}
        >
            <div className={`${containerClass ? containerClass : "container"}`}>
                <div className="row">
                    <div
                        className={`${
                            sideMenu ? "col-xl-4 col-sm-4" : "col-lg-4 col-sm-4"
                        }`}
                    >
                        {/* footer copyright */}
                        <FooterCopyright
                            footerLogo="/assets/img/logo/logo.png"
                            spaceBottomClass="mb-20"
                        />

                        {/* footer newsletter */}
                        <FooterNewsletter
                            spaceBottomClass="mb-20"
                            spaceLeftClass="ml-0"
                            sideMenu={sideMenu}
                        />
                    </div>

                    <div
                        className={`${
                            sideMenu ? "col-xl-8 col-sm-8" : "col-lg-8 col-sm-8"
                        } row`}
                    >
                        <div
                            className={`${
                                sideMenu
                                    ? "col-xl-4 col-sm-6"
                                    : "col-lg-4 col-sm-6"
                            }`}
                        >
                            <div className="footer-widget mb-30">
                                <div className="footer-title">
                                    <h3>{t("footer__group_about")}</h3>
                                </div>
                                <div className="footer-list">
                                    <ul>
                                        <li>
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/about"
                                                }
                                            >
                                                {t("footer__about_cr")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/locations"
                                                }
                                            >
                                                {t("footer__shop_locator")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/careers"
                                                }
                                            >
                                                {t("footer__career")}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${
                                sideMenu
                                    ? "col-xl-4 col-sm-6"
                                    : "col-lg-4 col-sm-6"
                            }`}
                        >
                            <div
                                className={`${
                                    sideMenu
                                        ? "footer-widget mb-30 ml-95"
                                        : "footer-widget mb-30"
                                }`}
                            >
                                <div className="footer-title">
                                    <h3>{t("footer__group_customer_care")}</h3>
                                </div>
                                <div className="footer-list">
                                    <ul>
                                        <li>
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/delivery"
                                                }
                                            >
                                                {t("footer__delivery")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/refund"
                                                }
                                            >
                                                {t("footer__refund")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/terms-of-service"
                                                }
                                            >
                                                {t("footer__terms")}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/privacy-policy"
                                                }
                                            >
                                                {t("footer__policy")}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${
                                sideMenu
                                    ? "col-xl-4 col-sm-6"
                                    : "col-lg-4 col-sm-6"
                            }`}
                        >
                            <div
                                className={`${
                                    sideMenu
                                        ? "footer-widget mb-30 ml-95"
                                        : "footer-widget mb-30"
                                }`}
                            >
                                <div className="footer-title">
                                    <h3>{t("footer__group_contact_us")}</h3>
                                </div>
                                <div className="footer-list">
                                    <ul>
                                        <li>
                                            <div className="d-flex">
                                                {t(
                                                    "footer__order_and_corporate"
                                                )}
                                            </div>
                                        </li>
                                        <li>
                                            <a href="mailto:orders@cuppingroom.hk">
                                                orders@cuppingroom.hk
                                            </a>
                                        </li>
                                        <li>
                                            <div className="d-flex">
                                                {t(
                                                    "footer__marketing_and_press"
                                                )}
                                            </div>
                                        </li>
                                        <li>
                                            <a
                                                className="mt-1"
                                                href="mailto:marketing@cuppingroom.hk"
                                            >
                                                marketing@cuppingroom.hk
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div
                            className={`${
                                sideMenu
                                    ? "col-xl-4 col-sm-6"
                                    : "col-lg-4 col-sm-6"
                            }`}
                        >
                            <div
                                className={`${
                                    sideMenu
                                        ? "footer-widget mb-30 ml-95"
                                        : "footer-widget mb-30"
                                }`}
                            >
                                <div className="footer-title">
                                    <h3>{t("footer__group_earn_reward")}</h3>
                                </div>
                                <div className="footer-list">
                                    <ul>
                                        <li>
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/my-account"
                                                }
                                            >
                                                {t("footer__membership")}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div
                            className={`${
                                sideMenu
                                    ? "col-xl-4 col-sm-6"
                                    : "col-lg-4 col-sm-6"
                            }`}
                        >
                            <div
                                className={`${
                                    sideMenu
                                        ? "footer-widget mb-30 ml-95"
                                        : "footer-widget mb-30"
                                }`}
                            >
                                <div className="footer-title">
                                    <h3>{t("footer__group_corporate")}</h3>
                                </div>
                                <div className="footer-list">
                                    <ul>
                                        <li>
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/corporate-corner"
                                                }
                                            >
                                                {t("footer__partner")}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div
                            className={`${
                                sideMenu
                                    ? "col-xl-4 col-sm-6"
                                    : "col-lg-4 col-sm-6"
                            }`}
                        >
                            <div
                                className={`${
                                    sideMenu
                                        ? "footer-widget mb-30 ml-95"
                                        : "footer-widget mb-30"
                                }`}
                            >
                                <div className="footer-title">
                                    <h3>
                                        {t("footer__group_connect_with_us")}
                                    </h3>
                                </div>
                                <div className="footer-social">
                                    <ul className="d-flex">
                                        <li className="me-2">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.facebook.com/cuppingroomhk/"
                                            >
                                                <i className="fa fa-facebook" />
                                            </a>
                                        </li>
                                        <li className="me-2">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://www.instagram.com/cuppingroom.hk/"
                                            >
                                                <i className="fa fa-instagram" />
                                            </a>
                                        </li>
                                        <li className="me-2">
                                            <a
                                                target="_blank"
                                                rel="noreferrer"
                                                href="https://hk.linkedin.com/company/cupping-room-coffee-roasters"
                                            >
                                                <i className="fa fa-linkedin" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* <div
                            className={`${
                                sideMenu
                                    ? "col-xl-4 col-sm-6"
                                    : "col-lg-8 col-sm-12"
                            }`}
                        >
                            
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
    );
};

FooterMain.propTypes = {
    backgroundColorClass: PropTypes.string,
    containerClass: PropTypes.string,
    extraFooterClass: PropTypes.string,
    sideMenu: PropTypes.bool,
    spaceBottomClass: PropTypes.string,
    spaceTopClass: PropTypes.string,
    spaceLeftClass: PropTypes.string,
    spaceRightClass: PropTypes.string,
};

export default FooterMain;
