import PropTypes from "prop-types";
import { Fragment } from "react";
import HeaderMain from "../wrappers/header/HeaderMain";
import FooterMain from "../wrappers/footer/FooterMain";
import ScrollToTop from "../components/scroll-to-top";

const LayoutMain = ({
    children,
    headerContainerClass,
    headerTop,
    headerPaddingClass,
    headerPositionClass,
}) => {
    return (
        <Fragment>
            <HeaderMain
                layout={headerContainerClass}
                top={headerTop}
                headerPaddingClass={headerPaddingClass}
                headerPositionClass={headerPositionClass}
            />
            {children}
            <FooterMain
                backgroundColorClass="bg-gray"
                spaceTopClass="pt-100"
                spaceBottomClass="pb-70"
            />
            <ScrollToTop />
        </Fragment>
    );
};

LayoutMain.propTypes = {
    children: PropTypes.node,
    headerContainerClass: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    headerPositionClass: PropTypes.string,
    headerTop: PropTypes.string,
};

export default LayoutMain;
