import axios from "axios";
import { CLIENT_ID } from "../../helpers/config";
import { handleErrorRes } from "../../helpers/utils";
import { logoutUser } from "./user-slice";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const categorySlice = createSlice({
    name: "category",
    initialState: {
        categories: [],
    },
    reducers: {
        setCategories(state, action) {
            state.categories = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCategories.fulfilled, (state, action) => {
            state.categories = action.payload.categories;
        });
    },
});

export const fetchCategories = createAsyncThunk(
    "category/fetchCategories",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/category_get.php",
                {
                    limit: 999,
                    offset: 0,
                    client_id: CLIENT_ID,
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const { setCategories } = categorySlice.actions;
export default categorySlice.reducer;
