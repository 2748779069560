import cogoToast from "cogo-toast";
import jwt_decode from "jwt-decode";
import DOMPurify from "dompurify";
import { TOAST_POSITION } from "./config";
import i18n from "../i18n";

export const handleErrorRes = (response, autoHideToast = true) => {
    if (response.data.status === 1) {
        return response.data;
    } else {
        let err_message = response.data.err_message;
        if (i18n.language === "zh-HK" && response.data.err_message_zh) {
            err_message = response.data.err_message_zh;
        }

        if (
            response.data?.code &&
            response.data?.code === "INVALID_EXPIRED_ACCESS_TOKEN"
        ) {
            err_message = "";
        }

        if (err_message) {
            const { hide } = cogoToast.error(err_message, {
                position: TOAST_POSITION,
                hideAfter: autoHideToast ? 5 : 0,
                onClick: () => {
                    hide();
                },
            });
        }
        throw new Error(err_message || "");
    }
};

export const toastSuccess = (responseData) => {
    console.log(responseData);
    if (responseData.status === 1) {
        let message = responseData.message;
        if (i18n.language === "zh-HK" && responseData.message_zh) {
            message = responseData.message_zh;
        }
        const { hide } = cogoToast.success(message, {
            position: TOAST_POSITION,
            hideAfter: 5,
            onClick: () => {
                hide();
            },
        });
    }
};

export const toastMessage = (message, variant) => {
    let fn;
    switch (variant) {
        case "success":
            fn = cogoToast.success;
            break;
        case "info":
            fn = cogoToast.info;
            break;
        case "error":
            fn = cogoToast.error;
            break;
        default:
            fn = cogoToast.error;
            break;
    }
    const { hide } = fn(message, {
        position: TOAST_POSITION,
        hideAfter: 5,
        onClick: () => {
            hide();
        },
    });
};

export const findCategories = (categories, category_id) => {
    let cat = categories.find((c) => c.id === category_id);
    if (cat) return cat;

    categories.forEach((category) => {
        let sub_cat = category.sub_categories.find(
            (sc) => sc.id === category_id
        );
        if (sub_cat) return sub_cat;
    });

    return null;
};

export const getVariantOptions = (variants = []) => {
    let variant_option_1 = null;
    let variant_option_2 = null;
    let variant_option_3 = null;

    variants.forEach((v) => {
        if (v.variant_name_1_en) {
            let option_value_obj = {
                value_en: v.variant_value_1_en,
                value_zh: v.variant_value_1_zh,
                value_cn: v.variant_value_1_cn,
            };
            if (variant_option_1) {
                if (
                    !variant_option_1.options
                        .map((o) => o.value_en)
                        .includes(v.variant_value_1_en)
                ) {
                    variant_option_1.options.push(option_value_obj);
                }
            } else {
                variant_option_1 = {
                    name_en: v.variant_name_1_en,
                    name_zh: v.variant_name_1_zh,
                    name_cn: v.variant_name_1_cn,
                    options: [option_value_obj],
                    variant_key: v.variant_key_1,
                };
            }
        }

        if (v.variant_name_2_en) {
            let option_value_obj = {
                value_en: v.variant_value_2_en,
                value_zh: v.variant_value_2_zh,
                value_cn: v.variant_value_2_cn,
            };
            if (variant_option_2) {
                if (
                    !variant_option_2.options
                        .map((o) => o.value_en)
                        .includes(v.variant_value_2_en)
                )
                    variant_option_2.options.push(option_value_obj);
            } else {
                variant_option_2 = {
                    name_en: v.variant_name_2_en,
                    name_zh: v.variant_name_2_zh,
                    name_cn: v.variant_name_2_cn,
                    options: [option_value_obj],
                    variant_key: v.variant_key_2,
                };
            }
        }

        if (v.variant_name_3_en) {
            let option_value_obj = {
                value_en: v.variant_value_3_en,
                value_zh: v.variant_value_3_zh,
                value_cn: v.variant_value_3_cn,
            };
            if (variant_option_3) {
                if (
                    !variant_option_3.options
                        .map((o) => o.value_en)
                        .includes(v.variant_value_3_en)
                )
                    variant_option_3.options.push(option_value_obj);
            } else {
                variant_option_3 = {
                    name_en: v.variant_name_3_en,
                    name_zh: v.variant_name_3_zh,
                    name_cn: v.variant_name_3_cn,
                    options: [option_value_obj],
                    variant_key: v.variant_key_3,
                };
            }
        }
    });

    return {
        variant_option_1,
        variant_option_2,
        variant_option_3,
    };
};

export function isTokenExpired(token) {
    try {
        const decoded = jwt_decode(token); // Use jwt-decode library
        console.log(decoded);
        if (decoded.exp < Date.now() / 1000) {
            // Check if token is expired
            return true;
        }
        return false;
    } catch (err) {
        return false;
    }
}

export const formatExternalUrl = (url) => {
    if ((url + "").startsWith("http")) {
        return url;
    } else if (!url) {
        return "#";
    } else {
        return process.env.PUBLIC_URL + url;
    }
};

export function stripHtml(html) {
    // Create a new div element
    const temporalDivElement = document.createElement("div");
    // Set the HTML content with the provided
    temporalDivElement.innerHTML = html;
    // Retrieve the text property of the element (innerText gives just the text, no HTML)
    return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

export function createMarkup(html, customStyles = []) {
    // Sanitize the HTML first to remove all style attributes
    // Add a hook to remove `style` attributes
    DOMPurify.addHook("uponSanitizeAttribute", function (node, data) {
        if (data.attrName === "style") {
            data.keepAttr = false;
        }
    });

    let cleanHtml = DOMPurify.sanitize(html, {
        ALLOWED_TAGS: [
            "b",
            "u",
            "i",
            "strong",
            "p",
            "h1",
            "h2",
            "h3",
            "h4",
            "h5",
            "h6",
            "br",
        ],
    });

    // Remove the hook after use
    DOMPurify.removeHook("uponSanitizeAttribute");

    // Create a temporary DOM container to manipulate the sanitized HTML
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = cleanHtml;

    // Apply custom styles
    if (customStyles.length > 0) {
        customStyles.forEach((cs) => {
            let tag = cs.tag;
            let style = cs.style;

            const tags = tempDiv.querySelectorAll(tag);
            tags.forEach((element) => {
                Object.keys(style).forEach((styleName) => {
                    element.style[styleName] = style[styleName];
                });
            });
        });
    }

    // Get the HTML with applied custom styles as a string
    const styledHtml = tempDiv.innerHTML;

    return { __html: styledHtml };
}

export const getOperatingSystem = () => {
    const userAgent = window.navigator.userAgent;
    if (/android/i.test(userAgent)) {
        return "android";
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return "ios";
    }
    return "other";
};
