import { Fragment, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { EffectFade, Thumbs } from "swiper";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Rating from "./sub-components/ProductRating";
import Swiper, { SwiperSlide } from "../swiper";
import {
    getDiscountPrice2,
    getProductCartQuantity,
} from "../../helpers/product";
import { addToCart } from "../../store/slices/cart-slice";
import {
    addToWishlist,
    deleteFromWishlist,
} from "../../store/slices/wishlist-slice";
import { addToCompare } from "../../store/slices/compare-slice";
import { getVariantOptions } from "../../helpers/utils";
import useLang from "../../hooks/use-lang";
import { useTranslation } from "react-i18next";

function ProductModal({
    product,
    currency,
    // discountedPrice,
    // finalProductPrice,
    // finalDiscountedPrice,
    show,
    onHide,
    wishlistItem,
    compareItem,
}) {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { getLangValue } = useLang();
    const { cartItems } = useSelector((state) => state.cart);

    let _product = product.variants?.length > 0 ? product.variants[0] : product;

    const [selectedVariant, setSelectedVariant] = useState(
        product.vairants?.[0] || product
    );
    const [selectedProductColor, setSelectedProductColor] = useState(
        product.variation ? product.variation[0].color : ""
    );
    const [selectedProductSize, setSelectedProductSize] = useState(
        product.variation ? product.variation[0].size[0].name : ""
    );
    //   const [productStock, setProductStock] = useState(
    //     product.variation ? product.variation[0].size[0].stock : product.stock
    //   );
    const { variant_option_1, variant_option_2, variant_option_3 } =
        getVariantOptions(product.variants);
    const [productStock, setProductStock] = useState(
        selectedVariant.inventory || 0
    );
    const [selectedOption1, setSelectedOption1] = useState(
        variant_option_1?.options?.[0] || null
    );
    const [selectedOption2, setSelectedOption2] = useState(
        variant_option_2?.options?.[0] || null
    );
    const [selectedOption3, setSelectedOption3] = useState(
        variant_option_3?.options?.[0] || null
    );

    const [quantityCount, setQuantityCount] = useState(1);
    const productCartQty = getProductCartQuantity(
        cartItems,
        product,
        selectedProductColor,
        selectedProductSize,
        selectedVariant
    );

    const discountedPrice = useMemo(() => {
        return getDiscountPrice2(
            selectedVariant.sale_price,
            selectedVariant.discount
        );
    }, [selectedVariant]);
    const finalProductPrice = useMemo(() => {
        return +(selectedVariant.retail_price * currency.currencyRate).toFixed(
            2
        );
    }, [selectedVariant, currency]);
    const finalDiscountedPrice = useMemo(() => {
        return +(discountedPrice * currency.currencyRate).toFixed(2);
    }, [discountedPrice, currency]);

    const gallerySwiperParams = {
        spaceBetween: 10,
        loop: true,
        effect: "fade",
        fadeEffect: {
            crossFade: true,
        },
        thumbs: { swiper: thumbsSwiper },
        modules: [EffectFade, Thumbs],
    };

    const thumbnailSwiperParams = {
        onSwiper: setThumbsSwiper,
        spaceBetween: 10,
        slidesPerView: 4,
        touchRatio: 0.2,
        freeMode: true,
        loop: true,
        slideToClickedSlide: true,
        navigation: true,
    };

    const onCloseModal = () => {
        setThumbsSwiper(null);
        onHide();
    };

    useEffect(() => {
        if (!product.variants || product.variants.length === 0) return;

        let _selectedVariant = product.variants.find((v) => {
            return (
                v.variant_value_1_en === (selectedOption1?.value_en || null) &&
                (!v.variant_value_2_en || v.variant_value_2_en === (selectedOption2?.value_en || null)) &&
                (!v.variant_value_3_en || v.variant_value_3_en === (selectedOption3?.value_en || null))
            );
        });

        setSelectedVariant(_selectedVariant || product);
        setProductStock(_selectedVariant?.inventory || 0);
    }, [product.variants, selectedOption1, selectedOption2, selectedOption3]);

    return (
        <Modal
            show={show}
            onHide={onCloseModal}
            className="product-quickview-modal-wrapper"
        >
            <Modal.Header closeButton></Modal.Header>

            <div className="modal-body">
                <div className="row">
                    <div className="col-md-5 col-sm-12 col-xs-12">
                        <div className="product-large-image-wrapper">
                            <Swiper options={gallerySwiperParams}>
                                {selectedVariant.image &&
                                    selectedVariant.image.map((img, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <div className="single-image">
                                                    <img
                                                        src={img}
                                                        className="img-fluid"
                                                        alt="Product"
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </div>
                        <div className="product-small-image-wrapper mt-15">
                            <Swiper options={thumbnailSwiperParams}>
                                {selectedVariant.image?.length > 1 &&
                                    selectedVariant.image.map((img, i) => {
                                        return (
                                            <SwiperSlide key={i}>
                                                <div className="single-image">
                                                    <img
                                                        src={img}
                                                        className="img-fluid"
                                                        alt=""
                                                    />
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })}
                            </Swiper>
                        </div>
                    </div>
                    <div className="col-md-7 col-sm-12 col-xs-12">
                        <div className="product-details-content quickview-content">
                            <h2>{getLangValue(product, "product_name")}</h2>
                            <div className="product-details-price">
                                {discountedPrice !== null ? (
                                    <Fragment>
                                        <span>
                                            {currency.currencySymbol +
                                                (finalDiscountedPrice).toLocaleString()}
                                        </span>{" "}
                                        <span className="old">
                                            {currency.currencySymbol +
                                                (finalProductPrice).toLocaleString()}
                                        </span>
                                    </Fragment>
                                ) : (
                                    <span>
                                        {currency.currencySymbol +
                                            (finalProductPrice).toLocaleString()}{" "}
                                    </span>
                                )}
                            </div>
                            {product.rating && product.rating > 0 ? (
                                <div className="pro-details-rating-wrap">
                                    <div className="pro-details-rating">
                                        <Rating ratingValue={product.rating} />
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            <div className="pro-details-list">
                                <p>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: getLangValue(
                                                product,
                                                "product_description"
                                            ),
                                        }}
                                    />
                                </p>
                            </div>

                            {variant_option_1 && (
                                <div className="pro-details-size-color">
                                    <div className="pro-details-size">
                                        <span>
                                            {getLangValue(
                                                variant_option_1,
                                                "name"
                                            )}
                                        </span>
                                        <div className="pro-details-size-content">
                                            {variant_option_1.options.map(
                                                (option, key) => {
                                                    return (
                                                        <label
                                                            className={`pro-details-size-content--single`}
                                                            key={key}
                                                        >
                                                            <input
                                                                type="radio"
                                                                value={option}
                                                                checked={
                                                                    option?.value_en ===
                                                                    selectedOption1?.value_en
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                onChange={() => {
                                                                    setSelectedOption1(
                                                                        option
                                                                    );
                                                                    setQuantityCount(
                                                                        1
                                                                    );
                                                                }}
                                                            />
                                                            <span className="size-name">
                                                                {getLangValue(
                                                                    option,
                                                                    "value"
                                                                )}
                                                            </span>
                                                        </label>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {variant_option_2 && (
                                <div className="pro-details-size-color mt-2">
                                    <div className="pro-details-size">
                                        <span>
                                            {getLangValue(
                                                variant_option_2,
                                                "name"
                                            )}
                                        </span>
                                        <div className="pro-details-size-content">
                                            {variant_option_2.options.map(
                                                (option, key) => {
                                                    return (
                                                        <label
                                                            className={`pro-details-size-content--single`}
                                                            key={key}
                                                        >
                                                            <input
                                                                type="radio"
                                                                value={option}
                                                                checked={
                                                                    option?.value_en ===
                                                                    selectedOption2?.value_en
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                onChange={() => {
                                                                    setSelectedOption2(
                                                                        option
                                                                    );
                                                                    setQuantityCount(
                                                                        1
                                                                    );
                                                                }}
                                                            />
                                                            <span className="size-name">
                                                                {getLangValue(
                                                                    option,
                                                                    "value"
                                                                )}
                                                            </span>
                                                        </label>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {variant_option_3 && (
                                <div className="pro-details-size-color mt-2">
                                    <div className="pro-details-size">
                                        <span>
                                            {getLangValue(
                                                variant_option_3,
                                                "name"
                                            )}
                                        </span>
                                        <div className="pro-details-size-content">
                                            {variant_option_3.options.map(
                                                (option, key) => {
                                                    return (
                                                        <label
                                                            className={`pro-details-size-content--single`}
                                                            key={key}
                                                        >
                                                            <input
                                                                type="radio"
                                                                value={option}
                                                                checked={
                                                                    option?.value_en ===
                                                                    selectedOption3?.value_en
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                onChange={() => {
                                                                    setSelectedOption3(
                                                                        option
                                                                    );
                                                                    setQuantityCount(
                                                                        1
                                                                    );
                                                                }}
                                                            />
                                                            <span className="size-name">
                                                                {getLangValue(
                                                                    option,
                                                                    "value"
                                                                )}
                                                            </span>
                                                        </label>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}

                            {product.variation ? (
                                <div className="pro-details-size-color">
                                    <div className="pro-details-color-wrap">
                                        <span>Color</span>
                                        <div className="pro-details-color-content">
                                            {product.variation.map(
                                                (single, key) => {
                                                    return (
                                                        <label
                                                            className={`pro-details-color-content--single ${single.color}`}
                                                            key={key}
                                                        >
                                                            <input
                                                                type="radio"
                                                                value={
                                                                    single.color
                                                                }
                                                                name="product-color"
                                                                checked={
                                                                    single.color ===
                                                                    selectedProductColor
                                                                        ? "checked"
                                                                        : ""
                                                                }
                                                                onChange={() => {
                                                                    setSelectedProductColor(
                                                                        single.color
                                                                    );
                                                                    setSelectedProductSize(
                                                                        single
                                                                            .size[0]
                                                                            .name
                                                                    );
                                                                    setProductStock(
                                                                        single
                                                                            .size[0]
                                                                            .stock
                                                                    );
                                                                    setQuantityCount(
                                                                        1
                                                                    );
                                                                }}
                                                            />
                                                            <span className="checkmark"></span>
                                                        </label>
                                                    );
                                                }
                                            )}
                                        </div>
                                    </div>
                                    <div className="pro-details-size">
                                        <span>Size</span>
                                        <div className="pro-details-size-content">
                                            {product.variation &&
                                                product.variation.map(
                                                    (single) => {
                                                        return single.color ===
                                                            selectedProductColor
                                                            ? single.size.map(
                                                                  (
                                                                      singleSize,
                                                                      key
                                                                  ) => {
                                                                      return (
                                                                          <label
                                                                              className={`pro-details-size-content--single`}
                                                                              key={
                                                                                  key
                                                                              }
                                                                          >
                                                                              <input
                                                                                  type="radio"
                                                                                  value={
                                                                                      singleSize.name
                                                                                  }
                                                                                  checked={
                                                                                      singleSize.name ===
                                                                                      selectedProductSize
                                                                                          ? "checked"
                                                                                          : ""
                                                                                  }
                                                                                  onChange={() => {
                                                                                      setSelectedProductSize(
                                                                                          singleSize.name
                                                                                      );
                                                                                      setProductStock(
                                                                                          singleSize.stock
                                                                                      );
                                                                                      setQuantityCount(
                                                                                          1
                                                                                      );
                                                                                  }}
                                                                              />
                                                                              <span className="size-name">
                                                                                  {
                                                                                      singleSize.name
                                                                                  }
                                                                              </span>
                                                                          </label>
                                                                      );
                                                                  }
                                                              )
                                                            : "";
                                                    }
                                                )}
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            {product.affiliateLink ? (
                                <div className="pro-details-quality">
                                    <div className="pro-details-cart btn-hover">
                                        <a
                                            href={product.affiliateLink}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            Buy Now
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                <div className="pro-details-quality">
                                    <div className="cart-plus-minus">
                                        <button
                                            onClick={() =>
                                                setQuantityCount(
                                                    quantityCount > 1
                                                        ? quantityCount - 1
                                                        : 1
                                                )
                                            }
                                            className="dec qtybutton"
                                        >
                                            -
                                        </button>
                                        <input
                                            className="cart-plus-minus-box"
                                            type="text"
                                            value={quantityCount}
                                            readOnly
                                        />
                                        <button
                                            onClick={() =>
                                                setQuantityCount(
                                                    quantityCount <
                                                        productStock -
                                                            productCartQty
                                                        ? quantityCount + 1
                                                        : quantityCount
                                                )
                                            }
                                            className="inc qtybutton"
                                        >
                                            +
                                        </button>
                                    </div>
                                    <div className="pro-details-cart btn-hover">
                                        {productStock && productStock > 0 ? (
                                            <button
                                                onClick={() =>
                                                    dispatch(
                                                        addToCart({
                                                            ...product,
                                                            quantity:
                                                                quantityCount,
                                                            selectedProductColor:
                                                                selectedProductColor
                                                                    ? selectedProductColor
                                                                    : product.selectedProductColor
                                                                    ? product.selectedProductColor
                                                                    : null,
                                                            selectedProductSize:
                                                                selectedProductSize
                                                                    ? selectedProductSize
                                                                    : product.selectedProductSize
                                                                    ? product.selectedProductSize
                                                                    : null,
                                                            ...selectedVariant,
                                                        })
                                                    )
                                                }
                                                disabled={
                                                    productCartQty >=
                                                    productStock
                                                }
                                            >
                                                {" "}
                                                {t("shop__add_to_cart")}{" "}
                                            </button>
                                        ) : (
                                            <button disabled>
                                                {t("shop__out_of_stock")}
                                            </button>
                                        )}
                                    </div>
                                    <div className="pro-details-wishlist">
                                        <button
                                            className={
                                                wishlistItem !== undefined
                                                    ? "active"
                                                    : ""
                                            }
                                            disabled={
                                                wishlistItem !== undefined &&
                                                false
                                            }
                                            title={
                                                wishlistItem !== undefined
                                                    ? t("shop__tooltip_remove_wishlist")
                                                    : t("shop__tooltip_add_wishlist")
                                            }
                                            onClick={() => {
                                                if (wishlistItem) {
                                                    dispatch(
                                                        deleteFromWishlist(
                                                            wishlistItem.id
                                                        )
                                                    );
                                                } else {
                                                    dispatch(
                                                        addToWishlist(product)
                                                    );
                                                }
                                            }}
                                        >
                                            <i
                                                className={`fa ${
                                                    wishlistItem !== undefined
                                                        ? "fa-heart"
                                                        : "fa-heart-o"
                                                }`}
                                            />
                                        </button>
                                    </div>
                                    {/* <div className="pro-details-compare">
                  <button
                    className={compareItem !== undefined ? "active" : ""}
                    disabled={compareItem !== undefined}
                    title={
                      compareItem !== undefined
                        ? "Added to compare"
                        : "Add to compare"
                    }
                    onClick={() => dispatch(addToCompare(product))}
                  >
                    <i className="pe-7s-shuffle" />
                  </button>
                </div> */}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

ProductModal.propTypes = {
    currency: PropTypes.shape({}),
    discountedprice: PropTypes.number,
    finaldiscountedprice: PropTypes.number,
    finalproductprice: PropTypes.number,
    onHide: PropTypes.func,
    product: PropTypes.shape({}),
    show: PropTypes.bool,
    wishlistItem: PropTypes.shape({}),
    compareItem: PropTypes.shape({}),
};

export default ProductModal;
