import cogoToast from "cogo-toast";
import { toastMessage, toastSuccess } from "../../helpers/utils";
import i18n from "../../i18n";
const { createSlice } = require("@reduxjs/toolkit");

const wishlistSlice = createSlice({
    name: "wishlist",
    initialState: {
        wishlistItems: [],
    },
    reducers: {
        addToWishlist(state, action) {
            const isInWishlist = state.wishlistItems.findIndex(
                (item) => item.id === action.payload.id
            );
            if (isInWishlist > -1) {
                // cogoToast.info("Product already in wishlist", {position: "bottom-left", hideAfter: 5});
                toastMessage(i18n.t("shop__message_already_in_wishlist"), "error");
            } else {
                state.wishlistItems.push(action.payload);
                // cogoToast.success("Added To wishlist", {position: "bottom-left", hideAfter: 5});
                toastMessage(i18n.t("shop__message_added_to_wishlist"), "success");
            }
        },
        deleteFromWishlist(state, action) {
            state.wishlistItems = state.wishlistItems.filter(
                (item) => item.id !== action.payload
            );
            // cogoToast.success("Removed From Wishlist", {position: "bottom-left",  hideAfter: 5});
            toastMessage(i18n.t("shop__message_removed_from_wishlist"), 'success');
        },
        deleteAllFromWishlist(state) {
            state.wishlistItems = [];
        },
    },
});

export const { addToWishlist, deleteFromWishlist, deleteAllFromWishlist } =
    wishlistSlice.actions;
export default wishlistSlice.reducer;
