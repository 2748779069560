import PropTypes from "prop-types";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import MenuCart from "./sub-components/MenuCart";
import { getProfile, logoutUser } from "../../store/slices/user-slice";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

const IconGroup = ({ iconWhiteClass }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const [keyword, setKeyword] = useState("");
    const searchPopupRef = useRef(null);
    const accountDropdownRef = useRef(null);
    const cartPopupRef = useRef(null);

    const onSearch = (e) => {
        e.preventDefault();
        navigate(process.env.PUBLIC_URL + `/shop?keyword=${keyword}`);
    };

    const handleClick = (e) => {
        e.currentTarget.nextSibling.classList.toggle("active");
    };

    const closePopups = () => {
        if (accountDropdownRef.current) {
            accountDropdownRef.current.classList.remove("active");
        }
        if (cartPopupRef.current) {
            cartPopupRef.current.classList.remove("active");
        }
    };

    const triggerMobileMenu = () => {
        const offcanvasMobileMenu = document.querySelector(
            "#offcanvas-mobile-menu"
        );
        offcanvasMobileMenu.classList.add("active");
    };
    const { token, user_profile } = useSelector((state) => state.user);
    const { compareItems } = useSelector((state) => state.compare);
    const { wishlistItems } = useSelector((state) => state.wishlist);
    const { cartItems } = useSelector((state) => state.cart);

    const onLogout = (e) => {
        e.preventDefault();
        dispatch(logoutUser());
    };

    useEffect(() => {
        if (token) {
            dispatch(getProfile());
        }
    }, [token, dispatch]);

    useEffect(() => {
        // Function to handle click event
        const handleClickOutside = (event) => {
            if (
                searchPopupRef.current &&
                !searchPopupRef.current.contains(event.target)
            ) {
                searchPopupRef.current.classList.remove("active");
            }
            if (
                accountDropdownRef.current &&
                !accountDropdownRef.current.contains(event.target)
            ) {
                accountDropdownRef.current.classList.remove("active");
            }
            if (
                cartPopupRef.current &&
                !cartPopupRef.current.contains(event.target)
            ) {
                cartPopupRef.current.classList.remove("active");
            }
        };

        // Adding click event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className={clsx("header-right-wrap mt-0", iconWhiteClass)}>
            <div className="same-style header-search d-none d-lg-block">
                <button
                    className="search-active"
                    onClick={(e) => handleClick(e)}
                >
                    <i className="pe-7s-search" />
                </button>
                <div className="search-content" ref={searchPopupRef}>
                    <form action="#">
                        <input
                            type="text"
                            placeholder={t("nav_menu__search_placeholder")}
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                        <button className="button-search" onClick={onSearch}>
                            <i className="pe-7s-search" />
                        </button>
                    </form>
                </div>
            </div>
            <div className="same-style account-setting d-none d-lg-block">
                <button
                    className="account-setting-active"
                    onClick={(e) => handleClick(e)}
                >
                    <i className="pe-7s-user-female" />
                </button>
                <div className="account-dropdown" ref={accountDropdownRef}>
                    {token && user_profile ? (
                        <div className="" style={{ whiteSpace: "nowrap" }}>
                            {t("nav_menu__welcome_message", {
                                user_name:
                                    user_profile.first_name ||
                                    user_profile.last_name
                                        ? user_profile.first_name +
                                          " " +
                                          user_profile.last_name
                                        : "User",
                            })}
                            <br />
                            {t("nav_menu__tier", {
                                tier_name: user_profile.tier_name,
                            })}
                            <hr />
                        </div>
                    ) : null}
                    <ul>
                        {!token ? (
                            <li>
                                <Link
                                    to={
                                        process.env.PUBLIC_URL +
                                        "/login-register"
                                    }
                                    state={{ from: location }}
                                >
                                    {t("nav_menu__login_register")}
                                </Link>
                            </li>
                        ) : null}
                        {/* { !token ? (<li>
                            <Link
                                to={process.env.PUBLIC_URL + "/login-register"}
                            >
                                Register
                            </Link>
                        </li>) : null } */}
                        {token ? (
                            <li>
                                <Link
                                    to={process.env.PUBLIC_URL + "/my-account"}
                                >
                                    {t("nav_menu__my_account")}
                                </Link>
                            </li>
                        ) : null}
                        {token ? (
                            <li>
                                <Link onClick={onLogout}>
                                    {t("nav_menu__logout")}
                                </Link>
                            </li>
                        ) : null}
                    </ul>
                </div>
            </div>
            {/* <div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + "/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">
            {compareItems && compareItems.length ? compareItems.length : 0}
          </span>
        </Link>
      </div> */}
            <div className="same-style header-wishlist">
                <Link to={process.env.PUBLIC_URL + "/wishlist"}>
                    <i className="pe-7s-like" />
                    <span className="count-style">
                        {wishlistItems && wishlistItems.length
                            ? wishlistItems.length
                            : 0}
                    </span>
                </Link>
            </div>
            <div className="same-style cart-wrap d-none d-lg-block">
                <button className="icon-cart" onClick={(e) => handleClick(e)}>
                    <i className="pe-7s-shopbag" />
                    <span className="count-style">
                        {cartItems && cartItems.length ? cartItems.length : 0}
                    </span>
                </button>
                {/* menu cart */}
                <MenuCart ref={cartPopupRef} />
            </div>
            <div className="same-style cart-wrap d-block d-lg-none">
                <Link
                    className="icon-cart"
                    to={process.env.PUBLIC_URL + "/cart"}
                >
                    <i className="pe-7s-shopbag" />
                    <span className="count-style">
                        {cartItems && cartItems.length ? cartItems.length : 0}
                    </span>
                </Link>
            </div>
            <div className="same-style mobile-off-canvas d-block d-lg-none">
                <button
                    className="mobile-aside-button"
                    onClick={() => triggerMobileMenu()}
                >
                    <i className="pe-7s-menu" />
                </button>
            </div>
        </div>
    );
};

IconGroup.propTypes = {
    iconWhiteClass: PropTypes.string,
};

export default IconGroup;
