import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setCurrency } from "../../../store/slices/currency-slice";
import { language_options } from "../../../i18n";

const MobileLangCurrChange = () => {
    const { i18n, t } = useTranslation();
    const dispatch = useDispatch();
    const currency = useSelector((state) => state.currency);
    const currency_options = currency.currency_options;

    const changeLanguageTrigger = (e) => {
        const languageCode = e.target.value;
        i18n.changeLanguage(languageCode);
        closeMobileMenu();
    };

    const setCurrencyTrigger = (e) => {
        const currencyName = e.target.value;
        dispatch(setCurrency(currencyName));
        closeMobileMenu();
    };

    const closeMobileMenu = () => {
        const offcanvasMobileMenu = document.querySelector(
            "#offcanvas-mobile-menu"
        );
        offcanvasMobileMenu.classList.remove("active");
    };

    return (
        <div className="mobile-menu-middle">
            <div className="lang-curr-style">
                <span className="title mb-2">
                    {t("nav_menu__choose_language")}{" "}
                </span>
                <select
                    value={
                        language_options.find(
                            (lo) => lo.value === i18n.resolvedLanguage
                        )?.value || language_options[0]?.value
                    }
                    onChange={changeLanguageTrigger}
                    style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                    {language_options.map((lo, loi) => (
                        <option key={`lang_${loi}`} value={lo.value}>
                            {lo.label}
                        </option>
                    ))}
                </select>
            </div>
            {/* <div className="lang-curr-style">
                <span className="title mb-2">Choose Currency</span>
                <select
                    value={currency.currencyName}
                    onChange={setCurrencyTrigger}
                >
                    {currency_options.map((co, coi) => (
                        <option key={`currency_${coi}`} value={co.currencyName}>
                            {co.currencyName}
                        </option>
                    ))}
                </select>
            </div> */}
        </div>
    );
};

export default MobileLangCurrChange;
