import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "react-bootstrap";
import useLang from "../../hooks/use-lang";
import { useTranslation } from "react-i18next";

function PointTransferResultModal({
    show,
    onHide,
    pointFromBefore,
    pointFromAfter,
    pointToBefore,
    pointToAfter,
}) {
    const { getLangValue } = useLang();
    const { t } = useTranslation();

    const onCloseModal = () => {
        onHide();
    };

    return (
        <Modal
            show={show}
            onHide={onCloseModal}
            size="sm"
            dialogClassName="point-transfer-result-modal-wrapper"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header closeButton>
                <Modal.Title>{t("my_account__get_credit_transfer_result_title")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>{t("my_account__get_credit_transfer_result_message")}</p>
                <div style={styles.balanceContainer}>
                    <p>
                        <strong>
                            {t(
                                "my_account__get_credit_transfer_result_updated_beans_balance"
                            )}
                            :{" "}
                        </strong>
                        {pointFromBefore} Beans &gt;{" "}
                        <strong>{pointFromAfter} Beans</strong>
                    </p>
                    <p>
                        <strong>
                            {t(
                                "my_account__get_credit_transfer_result_current_credit_balance"
                            )}
                            :{" "}
                        </strong>
                        {pointToBefore} Credit &gt;{" "}
                        <strong>{pointToAfter} Credit</strong>
                    </p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="primary"
                    onClick={onCloseModal}
                    style={styles.buttonStyle}
                >
                    {t("common__ok")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

PointTransferResultModal.propTypes = {
    onHide: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    pointFromBefore: PropTypes.number.isRequired,
    pointFromAfter: PropTypes.number.isRequired,
    pointToBefore: PropTypes.number.isRequired,
    pointToAfter: PropTypes.number.isRequired,
};

const styles = {
    buttonStyle: {
        backgroundColor: "#891717",
        borderColor: "#891717",
    },
    balanceContainer: {
        marginTop: "20px",
    },
};

export default PointTransferResultModal;
