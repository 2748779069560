import axios from "axios";
import {
    BEANS_POINT_TYPE,
    CLIENT_ID,
    CREDIT_POINT_TYPE,
} from "../../helpers/config";
import { handleErrorRes } from "../../helpers/utils";
import { logoutUser } from "./user-slice";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const productSlice = createSlice({
    name: "product",
    initialState: {
        products_status: "idle",
        products: [],
        total_products: 0,
        credit_products_status: "idle",
        credit_products: [],
        total_credit_products: 0,
        cash_credit_products_status: "idle",
        cash_credit_products: [],
        total_cash_credit_products: 0,
        sections: [],
        suggested_products_status: "idle",
        suggested_products: [],
        total_suggested_products: 0,
    },
    reducers: {
        setProducts(state, action) {
            state.products = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchProducts.pending, (state, action) => {
            state.products_status = "loading";
        });
        builder.addCase(fetchProducts.fulfilled, (state, action) => {
            state.products_status = "idle";
            state.products = action.payload.products;
            state.total_products = action.payload.total_rows || 0;
        });
        builder.addCase(fetchProducts.rejected, (state, action) => {
            state.products_status = "idle";
        });
        builder.addCase(fetchCreditProducts.pending, (state, action) => {
            state.credit_products_status = "loading";
        });
        builder.addCase(fetchCreditProducts.fulfilled, (state, action) => {
            state.credit_products_status = "idle";
            state.credit_products = action.payload.credit_products;
            state.total_credit_products = action.payload.total_rows || 0;
        });
        builder.addCase(fetchCreditProducts.rejected, (state, action) => {
            state.credit_products_status = "idle";
        });
        builder.addCase(fetchCashCreditProducts.pending, (state, action) => {
            state.cash_credit_products_status = "loading";
        });
        builder.addCase(fetchCashCreditProducts.fulfilled, (state, action) => {
            state.cash_credit_products_status = "idle";
            state.cash_credit_products = action.payload.cash_credit_products;
            state.total_cash_credit_products = action.payload.total_rows || 0;
        });
        builder.addCase(fetchCashCreditProducts.rejected, (state, action) => {
            state.cash_credit_products_status = "idle";
        });
        builder.addCase(fetchSectionProducts.fulfilled, (state, action) => {
            state.sections = action.payload.sections;
        });
        builder.addCase(fetchSuggestedProducts.pending, (state, action) => {
          state.suggested_products_status = "loading";
      });
      builder.addCase(fetchSuggestedProducts.fulfilled, (state, action) => {
          state.suggested_products_status = "idle";
          state.suggested_products = action.payload.products;
          state.total_suggested_products = action.payload.total_rows || 0;
      });
      builder.addCase(fetchSuggestedProducts.rejected, (state, action) => {
          state.suggested_products_status = "idle";
      });
    },
});

export const fetchProducts = createAsyncThunk(
    "category/fetchProducts",
    async (
        { category_id, keyword, offset, limit, sort_by },
        { dispatch, rejectWithValue, getState }
    ) => {
        try {
            const userState = getState().user;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/product_get.php",
                {
                    limit,
                    offset,
                    client_id: CLIENT_ID,
                    category_id,
                    sort_by,
                    keyword,
                },
                {
                    headers: {
                        Authorization: "Bearer " + userState.token,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchCreditProducts = createAsyncThunk(
    "category/fetchCreditProducts",
    async (
        { offset, limit, sort_by },
        { dispatch, rejectWithValue, getState }
    ) => {
        try {
            const userState = getState().user;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/credit_product_get.php",
                {
                    limit,
                    offset,
                    client_id: CLIENT_ID,
                    sort_by,
                    point_type_from: BEANS_POINT_TYPE,
                    point_type_to: CREDIT_POINT_TYPE,
                },
                {
                    headers: {
                        Authorization: "Bearer " + userState.token,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchCashCreditProducts = createAsyncThunk(
    "category/fetchCashCreditProducts",
    async (
        { offset, limit, sort_by },
        { dispatch, rejectWithValue, getState }
    ) => {
        try {
            const userState = getState().user;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/cash_credit_product_get.php",
                {
                    limit,
                    offset,
                    client_id: CLIENT_ID,
                    sort_by,
                    point_type_id: CREDIT_POINT_TYPE,
                },
                {
                    headers: {
                        Authorization: "Bearer " + userState.token,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchSectionProducts = createAsyncThunk(
    "category/fetchSectionProducts",
    async (_, { dispatch, rejectWithValue, getState }) => {
        try {
            const userState = getState().user;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/section_product_get.php",
                {
                    limit: 999,
                    offset: 0,
                    client_id: CLIENT_ID,
                },
                {
                    headers: {
                        Authorization: "Bearer " + userState.token,
                    },
                }
            );
            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchSuggestedProducts = createAsyncThunk(
  "category/fetchSuggestedProducts",
  async (
      { product_id },
      { dispatch, rejectWithValue, getState }
  ) => {
      try {
          const userState = getState().user;
          const response = await axios.post(
              process.env.REACT_APP_API_URL + "/suggested_product_get.php",
              {
                  limit: 5,
                  offset: 0,
                  client_id: CLIENT_ID,
                  product_id,
                  keyword_field: "flag_6", // tasting notes
              },
              {
                  headers: {
                      Authorization: "Bearer " + userState.token,
                  },
              }
          );

          return handleErrorRes(response);
      } catch (error) {
          // Check if the error response status is 401
          if (error.response.status === 401) {
              dispatch(logoutUser());
          }
          return rejectWithValue(error.message);
      }
  }
);

export const { setProducts } = productSlice.actions;
export default productSlice.reducer;
