import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

function PrivateRoute({ children, ...rest }) {
    const { token } = useSelector((state) => state.user);
    const location = useLocation();

    if (!token) {
        return (
            <Navigate
                to={process.env.PUBLIC_URL + "/login-register"}
                state={{ from: location }}
                replace
            />
        );
    }
    return children;
}

export default PrivateRoute;
