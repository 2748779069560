import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useMemo } from "react";
import {
    logoutAndFetchAccessToken,
    logoutUser,
} from "../../../store/slices/user-slice";
import useLang from "../../../hooks/use-lang";

const MobileNavMenu = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const { getLangValue } = useLang();
    const { categories } = useSelector((state) => state.category);
    const { token } = useSelector((state) => state.user);
    const dispatch = useDispatch();

    const navMenus = useMemo(() => {
        return [
            {
                label: t("nav_menu__about"),
                to: "/about",
                groups: [],
            },
            {
                label: t("nav_menu__shop"),
                to: "/shop",
                groups: categories.map((category, ci) => ({
                    label: getLangValue(category, "category_name"),
                    to: `/shop/${category.id}`,
                    group_items: category.sub_categories.map(
                        (sub_category, sci) => ({
                            label: getLangValue(sub_category, "category_name"),
                            to: `/shop/${sub_category.id}`,
                        })
                    ),
                })),
            },
            {
                label: t("nav_menu__subscription"),
                to: "/subscription",
                groups: [],
            },
            {
                label: t("nav_menu__locations"),
                to: "/locations",
                groups: [],
            },
            {
                label: t("nav_menu__like_a_champ"),
                to: "/like-a-champ",
                groups: [],
            },
            {
                label: t("nav_menu__corporate"),
                to: "/corporate-corner",
                groups: [],
            },
            {
                label: t("nav_menu__my_account"),
                to: "/my-account",
                groups: [],
            },
        ];
    }, [t, categories]);

    const onLogout = (e) => {
        e.preventDefault();
        dispatch(logoutUser());
    };

    return (
        <nav className="offcanvas-navigation" id="offcanvas-navigation">
            <ul>
                {navMenus.map((menu, mi) => (
                    <li
                        key={`menu_${mi}`}
                        className={
                            menu.groups.length > 0
                                ? "menu-item-has-children"
                                : ""
                        }
                    >
                        <Link to={process.env.PUBLIC_URL + menu.to}>
                            {menu.label}
                        </Link>
                        {menu.groups.length > 0 && (
                            <ul className="sub-menu">
                                {menu.groups.map((group, gi) => (
                                    <li
                                        key={`group_${gi}`}
                                        className={
                                            group.group_items.length > 0
                                                ? "menu-item-has-children"
                                                : ""
                                        }
                                    >
                                        <Link
                                            to={
                                                process.env.PUBLIC_URL +
                                                group.to
                                            }
                                        >
                                            {group.label}
                                        </Link>
                                        {group.group_items.length > 0 && (
                                            <ul className="sub-menu">
                                                {group.group_items.map(
                                                    (group_item, gii) => (
                                                        <li
                                                            key={`group_item_${gii}`}
                                                        >
                                                            <Link
                                                                to={
                                                                    process.env
                                                                        .PUBLIC_URL +
                                                                    group_item.to
                                                                }
                                                            >
                                                                {
                                                                    group_item.label
                                                                }
                                                            </Link>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
                <li>
                    {token ? (
                        <a onClick={onLogout} href="#">
                            {t("nav_menu__logout")}
                        </a>
                    ) : (
                        <Link
                            to={process.env.PUBLIC_URL + "/login-register"}
                            state={{ from: location }}
                        >
                            {t("nav_menu__login_register")}
                        </Link>
                    )}
                </li>
            </ul>
        </nav>
    );
};

export default MobileNavMenu;
