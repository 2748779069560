import PropTypes from "prop-types";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import SubscribeEmailV2 from "./sub-components/SubscribeEmailV2";

const FooterNewsletter = ({
    spaceBottomClass,
    spaceLeftClass,
    sideMenu,
    colorClass,
    widgetColorClass,
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={clsx(
                "footer-widget",
                spaceBottomClass,
                sideMenu ? "ml-ntv5" : spaceLeftClass,
                widgetColorClass
            )}
        >
            <div className="footer-title">
                <h3>{t("footer__subscribe")}</h3>
            </div>
            <div className={clsx("subscribe-style", colorClass)}>
                <p>{t("footer__subscribe_content")}</p>
                {/* subscribe email */}
                <SubscribeEmailV2 />
            </div>
        </div>
    );
};

FooterNewsletter.propTypes = {
    spaceBottomClass: PropTypes.string,
    spaceLeftClass: PropTypes.string,
    colorClass: PropTypes.string,
    widgetColorClass: PropTypes.string,
};

export default FooterNewsletter;
