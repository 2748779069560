import React, { useState, useEffect } from "react";
import { register } from "../../store/slices/user-slice";
import { useDispatch, useSelector } from "react-redux";
import cogoToast from "cogo-toast";
import { fetchCountries } from "../../store/slices/app-slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import useLang from "../../hooks/use-lang";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { CLIENT_ID } from "../../helpers/config";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function RegisterForm({ setActiveTab }) {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const { getLangValue } = useLang();
    const { countries } = useSelector((state) => state.app);
    const { register_status } = useSelector((state) => state.user);
    const [emailValid, setEmailValid] = useState(null);
    const [verifyEmailMessage, setVerifyEmailMessage] = useState(null);
    const [verifyEmailErrMessage, setVerifyEmailErrMessage] = useState(null);
    const [emailVerifying, setEmailVerifying] = useState(false);

    const navigate = useNavigate();

    const validationSchema = Yup.object({
        first_name: Yup.string().required(t("common__required_field")),
        last_name: Yup.string().required(t("common__required_field")),
        email: Yup.string()
            .email(t("common__email_validation"))
            .required(t("common__required_field")),
        password: Yup.string()
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                t("common__password_validation")
            )
            .required(t("common__required_field")),
        confirm_password: Yup.string()
            .oneOf([Yup.ref("password"), null], t("common__password_not_match"))
            .required(t("common__please_confirm_password")),
        phone_number: Yup.string().required(t("common__required_field")),
        country_id: Yup.string().required(t("common__required_field")),
        gender: Yup.string()
            .oneOf(
                ["male", "female", "rather_not_say"],
                t("common__gender_validation")
            )
            .required(t("common__required_field")),
        accept_terms: Yup.boolean().oneOf(
            [true],
            t("common__terms_validation")
        ),
        receive_marketing: Yup.boolean(),
    });

    const formik = useFormik({
        initialValues: {
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            confirm_password: "",
            country_id: "",
            phone_number: "",
            gender: "",
            accept_terms: false,
            receive_marketing: false,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // Replace this with your logic to send data to the server
            // console.log("Form data", values);
            console.log(values);

            if (!emailValid) {
                return;
            }

            try {
              await dispatch(register(values)).unwrap();
              navigate(process.env.PUBLIC_URL + '/my-account')
            } catch(err) {
              console.log(err)
            }
        },
    });

    const checkEmailExistence = async (email) => {
        try {
            setEmailValid(null);
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/verify_email.php",
                { email, client_id: CLIENT_ID }
            );
            // Assuming the API returns { isUsed: true } if the email is already used
            return response.data;
        } catch (error) {
            console.error("Error verifying email", error);
            // Handle error appropriately
            return { isUsed: true };
        }
    };

    const getInputStyle = () => {
        if (emailValid === true) {
            return { borderColor: "green", borderWidth: "2px" }; // Valid email style
        } else if (emailValid === false) {
            return { borderColor: "red", borderWidth: "2px" }; // Formik error style
        }
        return {}; // Default style
    };

    const handleEmailBlur = async (e) => {
        const { name, value } = e.target;
        formik.handleBlur(e); // Let Formik handle its own onBlur events
        // Extract the schema for the email field
        const emailSchema = Yup.reach(validationSchema, "email");
        // Now validate the email value against the extracted schema
        const isValid = await emailSchema.isValid(value);
        if (isValid) {
            // Check if the field is not empty
            setEmailVerifying(true);
            setVerifyEmailErrMessage(null);
            setVerifyEmailMessage(null);
            const { isUsed, err_message, err_message_zh, message, message_zh } =
                await checkEmailExistence(value);
            setEmailVerifying(false);
            if (isUsed) {
                setVerifyEmailErrMessage({ err_message, err_message_zh });

                setEmailValid(false);
            } else {
                formik.setFieldError(name, "");
                setVerifyEmailMessage({
                    message_zh,
                    message,
                });
                setEmailValid(true);
            }
        } else {
            formik.setFieldError(name, "");
            setEmailValid(null);
        }
    };

    const handleEmailChange = (e) => {
        // Call Formik's handleChange to ensure the input value is updated
        formik.handleChange(e);

        // Reset the email validation state as soon as the user starts typing
        setEmailValid(null);
        setVerifyEmailMessage(null);

        setVerifyEmailErrMessage("");
    };

    useEffect(() => {
        try {
            dispatch(fetchCountries());
        } catch (err) {
            console.log(err);
        }
    }, [dispatch]);

    return (
        <div className="login-form-container">
            <div className="login-register-form">
                <form onSubmit={formik.handleSubmit}>
                    {/* First Name */}
                    <div className="field">
                        <label htmlFor="first_name">
                            {t("form__first_name")}
                        </label>
                        <input
                            id="first_name"
                            name="first_name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.first_name}
                        />
                        {formik.touched.first_name &&
                        formik.errors.first_name ? (
                            <div className="text-danger">
                                {formik.errors.first_name}
                            </div>
                        ) : null}
                    </div>

                    {/* Last Name */}
                    <div className="field">
                        <label htmlFor="last_name">
                            {t("form__last_name")}
                        </label>
                        <input
                            id="last_name"
                            name="last_name"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.last_name}
                        />
                        {formik.touched.last_name && formik.errors.last_name ? (
                            <div className="text-danger">
                                {formik.errors.last_name}
                            </div>
                        ) : null}
                    </div>

                    {/* Email */}
                    <div className="field">
                        <label htmlFor="email">{t("form__email")}</label>
                        <input
                            id="email"
                            name="email"
                            type="email"
                            onChange={handleEmailChange}
                            value={formik.values.email}
                            autoComplete="off"
                            onBlur={handleEmailBlur}
                            // style={getInputStyle()}
                        />
                        {emailVerifying && (
                            <Spinner className="mt-2" size="sm" />
                        )}{" "}
                        {/* Loading indicator */}
                        {verifyEmailMessage ? (
                            <div className="text-success">
                                {i18n.language === "zh-HK"
                                    ? verifyEmailMessage.message_zh
                                    : verifyEmailMessage.message}
                            </div>
                        ) : null}
                        {verifyEmailErrMessage ? (
                            <div className="text-danger">
                                {i18n.language === "zh-HK"
                                    ? verifyEmailErrMessage.err_message_zh
                                    : verifyEmailErrMessage.err_message}
                            </div>
                        ) : null}
                        {formik.touched.email && formik.errors.email ? (
                            <div className="text-danger">
                                {formik.errors.email}
                            </div>
                        ) : null}
                    </div>

                    {/* Password */}
                    <div className="field">
                        <label htmlFor="password">{t("form__password")}</label>
                        <input
                            id="password"
                            name="password"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.password}
                            autoComplete="new-password"
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="text-danger">
                                {formik.errors.password}
                            </div>
                        ) : null}
                    </div>

                    {/* Confirm Password */}
                    <div className="field">
                        <label htmlFor="confirm_password">
                            {t("form__confirm_password")}
                        </label>
                        <input
                            id="confirm_password"
                            name="confirm_password"
                            type="password"
                            onChange={formik.handleChange}
                            value={formik.values.confirm_password}
                            autoComplete="new-password"
                        />
                        {formik.touched.confirm_password &&
                        formik.errors.confirm_password ? (
                            <div className="text-danger">
                                {formik.errors.confirm_password}
                            </div>
                        ) : null}
                    </div>

                    {/* Phone Number */}
                    <div className="field">
                        <label htmlFor="phone_number">{t("form__phone")}</label>
                        <input
                            id="phone_number"
                            name="phone_number"
                            type="text"
                            onChange={formik.handleChange}
                            value={formik.values.phone_number}
                        />
                        {formik.touched.phone_number &&
                        formik.errors.phone_number ? (
                            <div className="text-danger">
                                {formik.errors.phone_number}
                            </div>
                        ) : null}
                    </div>

                    {/* Country */}
                    <div className="field">
                        <label htmlFor="country_id">{t("form__country")}</label>
                        <select
                            id="country_id"
                            name="country_id"
                            onChange={formik.handleChange}
                            value={formik.values.country_id}
                        >
                            <option
                                value=""
                                label={t("form__country_placeholder")}
                            />
                            {countries.map((country) => (
                                <option key={country.id} value={country.id}>
                                    {getLangValue(country, "name")}
                                </option>
                            ))}
                        </select>
                        {formik.touched.country_id &&
                        formik.errors.country_id ? (
                            <div className="text-danger">
                                {formik.errors.country_id}
                            </div>
                        ) : null}
                    </div>

                    {/* Gender */}
                    <div className="field">
                        <label htmlFor="gender">{t("form__gender")}</label>
                        <select
                            id="gender"
                            name="gender"
                            onChange={formik.handleChange}
                            value={formik.values.gender}
                        >
                            <option
                                value=""
                                label={t("form__gender_placeholder")}
                            />
                            <option
                                value="male"
                                label={t("form__gender_male")}
                            />
                            <option
                                value="female"
                                label={t("form__gender_female")}
                            />
                            <option
                                value="rather_not_say"
                                label={t("form__gender_rather")}
                            />
                        </select>
                        {formik.touched.gender && formik.errors.gender ? (
                            <div className="text-danger">
                                {formik.errors.gender}
                            </div>
                        ) : null}
                    </div>

                    {/* Receive Marketing */}
                    <div className="field">
                        <label htmlFor="receive_marketing">
                            <input
                                id="receive_marketing"
                                name="receive_marketing"
                                type="checkbox"
                                onChange={formik.handleChange}
                                checked={formik.values.receive_marketing}
                            />
                            {t("form__receive_marketing")}
                        </label>
                        {formik.touched.receive_marketing &&
                        formik.errors.receive_marketing ? (
                            <div className="text-danger">
                                {formik.errors.receive_marketing}
                            </div>
                        ) : null}
                    </div>

                    {/* Accept Terms */}
                    <div className="field">
                        <label htmlFor="accept_terms">
                            <input
                                id="accept_terms"
                                name="accept_terms"
                                type="checkbox"
                                onChange={formik.handleChange}
                                checked={formik.values.accept_terms}
                            />
                            {t("form__accept_terms")}
                        </label>
                        {formik.touched.accept_terms &&
                        formik.errors.accept_terms ? (
                            <div className="text-danger">
                                {formik.errors.accept_terms}
                            </div>
                        ) : null}
                    </div>

                    {/* Submit Button */}
                    <div className="mt-3 d-flex gap-3 button-box">
                        <button
                            type="submit"
                            disabled={
                                register_status === "loading" || emailVerifying
                            }
                        >
                            {t("login__register")}
                        </button>

                        <button
                            type="button"
                            disabled={register_status === "loading"}
                            onClick={() => setActiveTab("login")}
                        >
                            {t("common__back")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
