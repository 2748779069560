const { createSlice } = require('@reduxjs/toolkit');

const currency_options = [
    { currencySymbol: '$', currencyRate: 1, currencyName: "HKD" },
    // { currencySymbol: '$', currencyRate: 1, currencyName: "SGD" },
]

const currencySlice = createSlice({
    name: "currency",
    initialState: {
        currencySymbol: "$",
        currencyName: "HKD",
        currencyRate: 1,
        currency_options: currency_options,
    },
    reducers: {
        setCurrency(state, action) {
            const currencyName = action.payload;

            let currency = currency_options.find(co => co.currencyName === currencyName);
            if (!currency) return;
            return state = {
                ...state,
                ...currency
            }
        }
    },
});

export const { setCurrency } = currencySlice.actions;
export default currencySlice.reducer;
