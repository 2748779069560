import React, { useState } from "react";
import Select from "react-select";
import countryTelephoneData from "country-telephone-data";
// Example options array
const areaCodeOptions = countryTelephoneData.allCountries.map((country) => ({
    value: country.dialCode,
    label: "+" + country.dialCode,
    areaCode: country.dialCode,
    countryName: country.name,
    flag: country.iso2.toLowerCase(),
}));

// Custom Option component
const CustomOption = ({ innerProps, isDisabled, data }) => {
    if (isDisabled) {
        return null;
    }

    return (
        <div {...innerProps} className="custom-option">
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "8px 12px",
                    gap: 5,
                }}
            >
                <span>{data.countryName}</span>
                <span>+{data.areaCode}</span>
            </div>
        </div>
    );
};

function PhoneCodeSelect({ value, onChange, id, name }) {
    const filterOption = ({ label, data }, inputText) => {
        return (
            data.countryName.toLowerCase().includes(inputText.toLowerCase()) ||
            data.areaCode.includes(inputText)
        );
    };

    return (
        <Select
            id={id}
            name={name}
            value={areaCodeOptions.find((option) => option.value === value)}
            onChange={(selectedOption) => {
                onChange(selectedOption.value);
            }}
            options={areaCodeOptions}
            components={{ Option: CustomOption }}
            filterOption={filterOption}
            styles={{
                valueContainer: (styles) => ({
                    ...styles,
                    margin: 0,
                    paddingTop: 0,
                    paddingBottom: 0,
                }),
                control: (styles) => ({
                    ...styles,
                    height: 45,
                    minHeight: 45,
                    borderColor: "#ebebeb",
                }),
                indicatorSeparator: (styles) => ({
                    ...styles,
                    margin: 0,
                    height: 45,
                    backgroundColor: "#ebebeb",
                }),
                input: (styles) => ({
                    ...styles,
                    margin: 0,
                    padding: 0,
                    height: 45,
                }),
                // Add custom styles for options here
                option: (styles) => ({
                    ...styles,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }),
            }}
        />
    );
}

export default PhoneCodeSelect;
