import React, { Fragment, useState } from "react";
import { Link, Navigate, useLocation } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import SEO from "../../components/seo";
import LayoutMain from "../../layouts/LayoutMain";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import LoginForm from "../../components/form/LoginForm";
import RegisterForm from "../../components/form/RegisterForm";

const CheckoutLogin = () => {
    let { pathname } = useLocation();
    const { token } = useSelector((state) => state.user);
    if (token) {
        return <Navigate to={process.env.PUBLIC_URL + "/checkout?guest_checkout=0"} replace />;
    }

    return (
        <Fragment>
            <SEO
                titleTemplate="Login"
                description="Login page of Cupping Room."
            />
            <LayoutMain headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        {
                            label: "Login Register",
                            path: process.env.PUBLIC_URL + pathname,
                        },
                    ]}
                />
                <div className="login-register-area pt-100 pb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 col-md-12 ms-auto me-auto">
                                <div className="login-register-wrapper">
                                    <Tab.Container defaultActiveKey="login">
                                        <Nav
                                            variant="pills"
                                            className="login-register-tab-list"
                                        >
                                            <Nav.Item>
                                                <Nav.Link eventKey="login">
                                                    <h4>Login</h4>
                                                </Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="register">
                                                    <h4>Register</h4>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="login">
                                                <LoginForm checkoutAsGuest />
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="register">
                                                <RegisterForm />
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Tab.Container>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </LayoutMain>
        </Fragment>
    );
};

export default CheckoutLogin;
