import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getOTP, login, submitOTP } from "../../store/slices/user-slice";
import { useTranslation } from "react-i18next";
import PhoneCodeSelect from "../PhoneCodeSelect";

export default function LoginForm({ checkoutAsGuest = false, setActiveTab }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [otp, setOtp] = useState("");
    const [areaCode, setAreaCode] = useState("852");
    const [loginOption, setLoginOption] = useState("phone");
    const [countdown, setCountdown] = useState(60);
    const [isResending, setIsResending] = useState(false);
    const [gettingOTP, setGettingOTP] = useState(false);
    const [otpError, setOtpError] = useState("");
    const { otp_get_status, otp_submit_status } = useSelector(
        (state) => state.user
    );
    const navigate = useNavigate();
    const areaCodeOptions = [
        { value: "+852", label: "+852" },
        { value: "+86", label: "+86" },
        // Add more options here
    ];

    const onLogin = (e) => {
        e.preventDefault();
        dispatch(
            login({
                email,
                password,
            })
        );
    };

    const handleOtpChange = useCallback((event) => {
        if (
            event.target.value === "" ||
            (event.target.value.match(/^\d+$/) &&
                event.target.value.length <= 6)
        ) {
            setOtp(event.target.value);
        }
    }, []);

    const handleResendClick = async (event) => {
        event.preventDefault();

        if (!phone || gettingOTP) return;

        // Place your resend OTP logic here
        try {
            setGettingOTP(true);
            await dispatch(
                getOTP({ phone: phone, area_code: areaCode })
            ).unwrap();
            setIsResending(true);
            setCountdown(60);
            setGettingOTP(false);
        } catch (err) {}
    };

    const handleSubmitOtp = async (event) => {
        event.preventDefault();
        // OTP verification logic here
        if (!phone || !otp) return;
        setOtpError("");
        try {
            let response = await dispatch(
                submitOTP({
                    phone: phone,
                    area_code: areaCode,
                    otp,
                })
            ).unwrap();

            if (response.status == 1) {
                if (response.new_user == 1) {
                    navigate(process.env.PUBLIC_URL + "/my-account");
                }
            } else {
                setOtpError(response.err_message || "");
            }
        } catch (err) {
            console.log(err);
            setOtpError(err || "");
        }
    };

    useEffect(() => {
        let intervalId;

        // Start the countdown when isResending is true
        if (isResending && countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }

        // When countdown reaches 0, clear the interval and reset isResending
        if (countdown === 0) {
            setIsResending(false);
            clearInterval(intervalId);
        }

        // Clear interval on unmount
        return () => clearInterval(intervalId);
    }, [isResending, countdown]);

    return (
        <div className="login-form-container">
            <div className="login-register-form">
                <form>
                    <div className="row gx-1">
                        <div className="col-12 col-md-12 field">
                            <select
                                className="custom-select"
                                id="login_option"
                                name="login_option"
                                value={loginOption}
                                onChange={(e) => setLoginOption(e.target.value)}
                            >
                                <option
                                    value="phone"
                                    label={t("login__option_phone")}
                                />
                                <option
                                    value="email"
                                    label={t("login__option_email")}
                                />
                            </select>
                        </div>
                        {loginOption === "phone" && (
                            <div className="col-12 col-md-6 field">
                                {/* <select
                                    className="custom-select"
                                    id="area_code"
                                    name="area_code"
                                    value={areaCode}
                                    onChange={(e) =>
                                        setAreaCode(e.target.value)
                                    }
                                >
                                    <option value="+852" label="+852" />
                                </select> */}
                                <PhoneCodeSelect
                                    id="area_code"
                                    name="area_code"
                                    value={areaCode}
                                    onChange={setAreaCode}
                                />
                            </div>
                        )}

                        {loginOption === "email" ? (
                            <div className="col-12 col-md-12 field">
                                <input
                                    type="text"
                                    name="email"
                                    placeholder={t("login__email")}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        ) : (
                            <div className="col-12 col-md-6 field">
                                <input
                                    type="tel"
                                    name="phone"
                                    placeholder={t("login__phone")}
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    autoComplete="off"
                                />
                            </div>
                        )}
                    </div>

                    <div className="d-flex gap-1">
                        <div className="field w-100">
                            {loginOption === "email" ? (
                                <input
                                    type="password"
                                    name="password"
                                    placeholder={t("login__password")}
                                    value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    autoComplete="new-password"
                                />
                            ) : (
                                <input
                                    type="tel"
                                    name="otp"
                                    placeholder={t("login__otp_code")}
                                    value={otp}
                                    onChange={handleOtpChange}
                                    autoComplete="one-time-code"
                                    textcontenttype="oneTimeCode"
                                    maxLength={6}
                                    pattern="\\d*"
                                    inputMode="numeric"
                                />
                            )}
                        </div>

                        {loginOption === "phone" && (
                            <div className="field">
                                <button
                                    className="custom-button"
                                    type="button"
                                    onClick={handleResendClick}
                                    disabled={
                                        isResending ||
                                        gettingOTP ||
                                        phone === ""
                                    }
                                >
                                    <div className="">
                                        {t("login__otp_send")}
                                        {isResending && countdown > 0 ? (
                                            <span>
                                                {" (" + countdown + ")"}
                                            </span>
                                        ) : null}
                                    </div>
                                </button>
                            </div>
                        )}
                    </div>
                    <div className="button-box">
                        <div className="login-toggle-btn d-flex flex-wrap-reverse gap-3 align-items-center justify-content-between">
                            {/* <input type="checkbox" />
                            <label className="ml-10">
                                {t("login__remember_me")}
                            </label> */}
                            {loginOption === "email" ? (
                                <div className="d-inline-flex gap-3">
                                    <button type="submit" onClick={onLogin}>
                                        <span>{t("login__login")}</span>
                                    </button>
                                    <button
                                        type="button"
                                        onClick={() => setActiveTab("register")}
                                    >
                                        <span>{t("login__register")}</span>
                                    </button>
                                </div>
                            ) : (
                                <button type="submit" onClick={handleSubmitOtp}>
                                    <span>{t("login__login_register")}</span>
                                </button>
                            )}
                            <Link
                                to={process.env.PUBLIC_URL + "/forgot-password"}
                            >
                                {t("login__forgot_password")}
                            </Link>
                        </div>

                        {checkoutAsGuest && (
                            <div className="mt-5 d-flex flex-column align-items-center">
                                <h4 className="fw-bold fs-5">
                                    {t("login__or_checkout_as_guest")}
                                </h4>
                                <Link
                                    to={
                                        process.env.PUBLIC_URL +
                                        "/checkout?guest_checkout=1"
                                    }
                                    className="mt-3"
                                    type="submit"
                                >
                                    <button type="button">
                                        <span>
                                            {t("login__checkout_as_guest")}
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        )}
                    </div>
                </form>
            </div>
        </div>
    );
}
