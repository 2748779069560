import PropTypes from "prop-types";
import React, { Fragment } from "react";
import CreditShopTopAction from "../../components/product/CreditShopTopAction";

const CreditShopTopbar = ({
  getLayout,
  getFilterSortParams,
  shopType,
  setShopType,
  productCount,
  sortedProductCount
}) => {
  return (
    <Fragment>
      {/* shop top action */}
      <CreditShopTopAction
        getLayout={getLayout}
        getFilterSortParams={getFilterSortParams}
        shopType={shopType}
        setShopType={setShopType}
        productCount={productCount}
        sortedProductCount={sortedProductCount}
      />
    </Fragment>
  );
};

CreditShopTopbar.propTypes = {
  getLayout: PropTypes.func,
  productCount: PropTypes.number,
  sortedProductCount: PropTypes.number
};

export default CreditShopTopbar;
