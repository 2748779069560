import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import CreditProductGridListSingle from "../../components/product/CreditProductGridListSingle";
import { useTranslation } from "react-i18next";

const CreditProductGridList = ({ products, spaceBottomClass }) => {
    const currency = useSelector((state) => state.currency);
    const { t } = useTranslation();
    return (
        <Fragment>
            {products.length === 0 && (
                <div>{t("my_account__get_credit_beans_to_credit_empty")}</div>
            )}
            {products?.map((product) => {
                return (
                    <div className="col-xl-3 col-sm-6" key={product.id}>
                        <CreditProductGridListSingle
                            spaceBottomClass={spaceBottomClass}
                            product={product}
                            currency={currency}
                        />
                    </div>
                );
            })}
        </Fragment>
    );
};

CreditProductGridList.propTypes = {
    products: PropTypes.array,
    spaceBottomClass: PropTypes.string,
};

export default CreditProductGridList;
