import { Fragment, useEffect, useState } from "react";
import SEO from "../../components/seo";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import LayoutMain from "../../layouts/LayoutMain";
import SectionTitleTwo from "../../components/section-title/SectionTitleTwo";
import OrderDetail from "../../components/order/OrderDetail";
import axios from "axios";
import { CLIENT_ID } from "../../helpers/config";
import { handleErrorRes } from "../../helpers/utils";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { fetchCart } from "../../store/slices/cart-slice";

const OrderConfirmation = () => {
    let { pathname } = useLocation();
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { order_token } = useParams();
    const [orderDetail, setOrderDetail] = useState(null);

    const fetchOrderDetail = async () => {
        try {
            let response = await axios.post(
                process.env.REACT_APP_API_URL + "/order_detail_get.php",
                {
                    client_id: CLIENT_ID,
                    order_token,
                }
            );

            let data = handleErrorRes(response);
            console.log(data);
            setOrderDetail(data.order);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (order_token) {
            fetchOrderDetail();
            dispatch(fetchCart());
        }
    }, [order_token]);

    return (
        <Fragment>
            <SEO
                titleTemplate={t("order_confirmation__seo_title")}
                description={t("order_confirmation__seo_desc")}
            />
            <LayoutMain headerTop="visible">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: t("home__breadcrumb"), path: process.env.PUBLIC_URL + "/" },
                        {
                            label: t("order_confirmation__breadcrumb"),
                            path: process.env.PUBLIC_URL + pathname,
                        },
                    ]}
                />
                <div className="order-confirmation-area pt-40 pb-100">
                    <div className="container">
                        <SectionTitleTwo
                            titleText={t("order_confirmation__page_title")}
                            subTitleText={t("order_confirmation__page_subtitle")}
                            positionClass="text-center"
                            spaceClass="mb-60"
                        />

                        <OrderDetail orderDetail={orderDetail} />

                        {/* <div className="row justify-content-center">
                            <div className="col-xl-7 col-lg-8 text-center">
                                <div className="error">
                                    <h2>Order No.: {order_token}</h2>
                                    <p>Thank you for your order!</p>
                                </div>
                                <div className="mt-40">
                                    <Link to={process.env.PUBLIC_URL + "/"}>
                                        Back to home page
                                    </Link>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </LayoutMain>
        </Fragment>
    );
};

export default OrderConfirmation;
