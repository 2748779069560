import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

export const language_options = [
    { value: "en", label: "English" },
    { value: "zh-HK", label: "繁體中文" },
    // { value: 'zh-CN', label: '简体中文' },
];

const version = 8;

i18n
    // load translation using http -> see /public/locales
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: (code) => {
            if (code === "zh-CN" || code === "zh") {
                return "zh-HK";
            } else if (code === "zh-HK") {
                return "zh-HK";
            }
            return "en";
        },
        debug: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath:
                process.env.PUBLIC_URL +
                "/locales/{{lng}}/{{ns}}.json?v=" +
                version, // adjust the path according to your setup
        },
    });

export default i18n;
