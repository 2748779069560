import PropTypes from "prop-types";

import { setActiveLayout } from "../../helpers/product";
import { useTranslation } from "react-i18next";

const CreditShopTopAction = ({
    getLayout,
    getFilterSortParams,
    shopType,
    setShopType,
    productCount,
    sortedProductCount,
}) => {
    const { t } = useTranslation();
    return (
        <div className="shop-top-bar mb-35">
            <div className="select-shoing-wrap">
                <div className="shop-select">
                    <select
                        onChange={(e) =>
                            getFilterSortParams("filterSort", e.target.value)
                        }
                    >
                        <option value="default">
                            {t("shop__sort_default")}
                        </option>
                        <option value="priceHighToLow">
                            {t("shop__sort_price_desc")}
                        </option>
                        <option value="priceLowToHigh">
                            {t("shop__sort_price_asc")}
                        </option>
                    </select>
                </div>
                <p>
                    {t("shop__list_record", {
                        sortedProductCount,
                        productCount,
                    })}
                </p>
            </div>

            <div className="select-shoing-wrap">
                <div className="shop-select me-0">
                    <select
                        value={shopType}
                        onChange={(e) => setShopType(e.target.value)}
                    >
                        <option value="beans">
                            {t("my_account__get_credit_beans_to_credit")}
                        </option>
                        <option value="cash">
                            {t("my_account__get_credit_cash_to_credit")}
                        </option>
                    </select>
                </div>
            </div>

            {/* <div className="shop-tab">
        <button
          onClick={e => {
            getLayout("grid two-column");
            setActiveLayout(e);
          }}
        >
          <i className="fa fa-th-large" />
        </button>
        <button
          onClick={e => {
            getLayout("grid three-column");
            setActiveLayout(e);
          }}
        >
          <i className="fa fa-th" />
        </button>
        <button
          onClick={e => {
            getLayout("list");
            setActiveLayout(e);
          }}
        >
          <i className="fa fa-list-ul" />
        </button>
      </div> */}
        </div>
    );
};

CreditShopTopAction.propTypes = {
    getFilterSortParams: PropTypes.func,
    getLayout: PropTypes.func,
    productCount: PropTypes.number,
    sortedProductCount: PropTypes.number,
};

export default CreditShopTopAction;
