import React, { useState, useEffect } from "react";
import Skeleton from "@mui/material/Skeleton";

const ImageLoader = ({ className, style = {}, src, alt, variant = "rectangular", width = '100%', height = 360 }) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => setLoaded(true);
        // img.onerror = () => setLoaded(true);
    }, [src]);

    return (
        <>
            {!loaded && (
                <Skeleton
                    variant={variant}
                    width={width}
                    height={height}
                    animation="wave"
                    className={className}
                    style={style}
                />
            )}
            <img
                className={className}
                src={src}
                alt={alt}
                style={{ display: loaded ? "block" : "none", ...style }}
                onLoad={() => setLoaded(true)}
            />
        </>
    );
};

export default ImageLoader;
