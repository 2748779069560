import { useTranslation } from "react-i18next";

const MobileWidgets = () => {
    const { t } = useTranslation();
    return (
        <div className="offcanvas-widget-area">
            <div className="off-canvas-contact-widget">
                <div className="header-contact-info">
                    <ul className="header-contact-info__list">
                        {/* <li>
                            <i className="fa fa-phone"></i>{" "}
                            <a href="tel://12452456012">(1245) 2456 012 </a>
                        </li> */}
                        <li>
                            <i className="fa fa-envelope"></i>{" "}
                            <ul>
                                <li>
                                    {t("nav_menu__order_and_corporate")}
                                    <br />
                                    <a href="mailto:orders@cuppingroom.hk">
                                        orders@cuppingroom.hk
                                    </a>
                                </li>
                                <li>
                                    {t("nav_menu__marketing_and_press")}
                                    <br />
                                    <a href="mailto:orders@cuppingroom.hk">
                                        marketing@cuppingroom.hk
                                    </a>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
            {/*Off Canvas Widget Social Start*/}
            <div className="off-canvas-widget-social">
                <a
                    href="https://www.facebook.com/cuppingroomhk/"
                    title="Facebook"
                >
                    <i className="fa fa-facebook"></i>
                </a>
                <a
                    href="https://www.instagram.com/cuppingroom.hk/"
                    title="Instagram"
                >
                    <i className="fa fa-instagram"></i>
                </a>
                <a
                    href="https://hk.linkedin.com/company/cupping-room-coffee-roasters"
                    title="Linkedin"
                >
                    <i className="fa fa-linkedin"></i>
                </a>
            </div>
            {/*Off Canvas Widget Social End*/}
        </div>
    );
};

export default MobileWidgets;
