import PropTypes from "prop-types";
import clsx from "clsx";
import {
    getIndividualCategories,
    getIndividualTags,
    getIndividualColors,
    getProductsIndividualSizes,
} from "../../helpers/product";
import ShopSearch from "../../components/product/ShopSearch";
import ShopCategories from "../../components/product/ShopCategories";
import ShopColor from "../../components/product/ShopColor";
import ShopSize from "../../components/product/ShopSize";
import ShopTag from "../../components/product/ShopTag";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useLang from "../../hooks/use-lang";

const ShopSidebar = ({ products, getSortParams, sideSpaceClass }) => {
    const { i18n, t } = useTranslation();
    const { getLangValue } = useLang();
    const uniqueCategories = getIndividualCategories(products);
    const uniqueColors = getIndividualColors(products);
    const uniqueSizes = getProductsIndividualSizes(products);
    const uniqueTags = getIndividualTags(products);
    const { categories } = useSelector((state) => state.category);
    const { category_id } = useParams();
    const categoryName = useMemo(() => {
        if (!category_id) return t("shop__all_categories");

        let cat = categories.find((c) => c.id == category_id);
        if (cat) {
            return getLangValue(cat, "category_name");
        } else {
            return "";
        }
    }, [categories, category_id, i18n.language, t]);

    return (
        <div className={clsx("sidebar-style", sideSpaceClass)}>
            <h2 className="mb-3">{categoryName}</h2>

            {/* shop search */}
            <ShopSearch />

            {/* filter by categories */}
            <ShopCategories
                categories={uniqueCategories}
                getSortParams={getSortParams}
            />

            {/* filter by color */}
            {/* <ShopColor colors={uniqueColors} getSortParams={getSortParams} /> */}

            {/* filter by size */}
            {/* <ShopSize sizes={uniqueSizes} getSortParams={getSortParams} /> */}

            {/* filter by tag */}
            {/* <ShopTag tags={uniqueTags} getSortParams={getSortParams} /> */}
        </div>
    );
};

ShopSidebar.propTypes = {
    getSortParams: PropTypes.func,
    products: PropTypes.array,
    sideSpaceClass: PropTypes.string,
};

export default ShopSidebar;
