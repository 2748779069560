import PropTypes from "prop-types";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass }) => {
    const { t } = useTranslation();
    return (
        <div className={clsx("copyright", spaceBottomClass, colorClass)}>
            <div className="footer-logo">
                <Link to={process.env.PUBLIC_URL + "/"}>
                    <img alt="" src={process.env.PUBLIC_URL + footerLogo} />
                </Link>
            </div>
            <p>
                &copy; {t("footer__copyright1")} {new Date().getFullYear()}
                <br /> {t("footer__copyright2")}
            </p>
        </div>
    );
};

FooterCopyright.propTypes = {
    footerLogo: PropTypes.string,
    spaceBottomClass: PropTypes.string,
    colorClass: PropTypes.string,
};

export default FooterCopyright;
