import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { isTokenExpired } from "../helpers/utils";
import { logoutUser } from "../store/slices/user-slice";

export default function TokenCheckWrapper({ children }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { token } = useSelector(state => state.user);

    useEffect(() => {
        // Check token expiry whenever the location changes
        if (token && isTokenExpired(token)) {
            // Handle the token expiry (e.g., redirecting to login, removing token, etc.)
            dispatch(logoutUser());
            navigate(process.env.PUBLIC_URL + '/login-register');  // Redirect to login page
        }
    }, [location, navigate, dispatch, token]);

    return children
}