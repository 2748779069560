import { v4 as uuidv4 } from "uuid";
import cogoToast from "cogo-toast";
import { handleErrorRes, toastMessage } from "../../helpers/utils";
import axios from "axios";
import { CLIENT_ID, CREDIT_POINT_TYPE } from "../../helpers/config";
import { logoutUser } from "./user-slice";
import i18n from "../../i18n";
const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        status: "idle",
        cartItems: [],
        cartCredit: 0,
        cartCouponCode: "",
        subtotal: 0,
        credit_discount: 0,
        coupon_discount: 0,
        shipping_fee: 0,
        total: 0,
        deliveryCountryId: null,
        shippingMethod: 'delivery',
        cart_messages: {},
    },
    reducers: {
        setCartCredit(state, action) {
            state.cartCredit = action.payload;
        },
        setCouponCode(state, action) {
            state.cartCouponCode = action.payload;
        },
        setDeliveryCountryId(state, action) {
            state.deliveryCountryId = action.payload;
        },
        setShippingMethod(state, action) {
            state.shippingMethod = action.payload;
        },
        // addToCart(state, action) {
        //     const product = action.payload;
        //     if(!product.variation){
        //         const cartItem = state.cartItems.find(item => item.id === product.id);
        //         if(!cartItem){
        //             state.cartItems.push({
        //                 ...product,
        //                 quantity: product.quantity ? product.quantity : 1,
        //                 cartItemId: uuidv4()
        //             });
        //         } else {
        //             state.cartItems = state.cartItems.map(item => {
        //                 if(item.cartItemId === cartItem.cartItemId){
        //                     return {
        //                         ...item,
        //                         quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1
        //                     }
        //                 }
        //                 return item;
        //             })
        //         }
        //     } else {
        //         const cartItem = state.cartItems.find(
        //             item =>
        //                 item.id === product.id &&
        //                 product.selectedProductColor &&
        //                 product.selectedProductColor === item.selectedProductColor &&
        //                 product.selectedProductSize &&
        //                 product.selectedProductSize === item.selectedProductSize &&
        //                 (product.cartItemId ? product.cartItemId === item.cartItemId : true)
        //         );
        //         if(!cartItem){
        //             state.cartItems.push({
        //                 ...product,
        //                 quantity: product.quantity ? product.quantity : 1,
        //                 cartItemId: uuidv4()
        //             });
        //         } else if (cartItem !== undefined && (cartItem.selectedProductColor !== product.selectedProductColor || cartItem.selectedProductSize !== product.selectedProductSize)) {
        //             state.cartItems = [
        //                 ...state.cartItems,
        //                 {
        //                     ...product,
        //                     quantity: product.quantity ? product.quantity : 1,
        //                     cartItemId: uuidv4()
        //                 }
        //             ]
        //         } else {
        //             state.cartItems = state.cartItems.map(item => {
        //                 if(item.cartItemId === cartItem.cartItemId){
        //                     return {
        //                         ...item,
        //                         quantity: product.quantity ? item.quantity + product.quantity : item.quantity + 1,
        //                         selectedProductColor: product.selectedProductColor,
        //                         selectedProductSize: product.selectedProductSize
        //                     }
        //                 }
        //                 return item;
        //             });
        //         }
        //     }
        //     cogoToast.success("Added To Cart", {position: "bottom-left"});
        // },
        // deleteFromCart(state, action) {
        //     state.cartItems = state.cartItems.filter(item => item.cartItemId !== action.payload);
        //     cogoToast.error("Removed From Cart", {position: "bottom-left"});
        // },
        // decreaseQuantity(state, action){
        //     const product = action.payload;
        //     if (product.quantity === 1) {
        //         state.cartItems = state.cartItems.filter(item => item.cartItemId !== product.cartItemId);
        //         cogoToast.error("Removed From Cart", {position: "bottom-left"});
        //     } else {
        //         state.cartItems = state.cartItems.map(item =>
        //             item.cartItemId === product.cartItemId
        //                 ? { ...item, quantity: item.quantity - 1 }
        //                 : item
        //         );
        //         cogoToast.warn("Item Decremented From Cart", {position: "bottom-left"});
        //     }
        // },
        // deleteAllFromCart(state){
        //     state.cartItems = []
        // }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCart.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(fetchCart.fulfilled, (state, action) => {
            state.cartItems = action.payload.cart_items;
            state.subtotal = action.payload.subtotal;
            state.credit_discount = action.payload.credit_discount;
            state.coupon_discount = action.payload.coupon_discount;
            state.total = action.payload.total;
            state.shipping_fee = action.payload.shipping_fee;
            state.cartCredit = action.payload.credit_amount;
            state.cartCouponCode = action.payload.coupon_code;
            state.cart_messages = action.payload.messages
            state.status = "idle";
        });
        builder.addCase(fetchCart.rejected, (state, action) => {
            state.status = "idle";
        });
        builder.addCase(addToCart.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(addToCart.fulfilled, (state, action) => {
            state.cartItems = action.payload.cart_items;
            state.subtotal = action.payload.subtotal;
            state.credit_discount = action.payload.credit_discount;
            state.coupon_discount = action.payload.coupon_discount;
            state.total = action.payload.total;
            state.shipping_fee = action.payload.shipping_fee;
            state.cartCredit = action.payload.credit_amount;
            state.cartCouponCode = action.payload.coupon_code;
            state.cart_messages = action.payload.messages
            state.status = "idle";
        });
        builder.addCase(addToCart.rejected, (state, action) => {
            state.status = "idle";
        });
        builder.addCase(removeFromCart.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(removeFromCart.fulfilled, (state, action) => {
            state.cartItems = action.payload.cart_items;
            state.subtotal = action.payload.subtotal;
            state.credit_discount = action.payload.credit_discount;
            state.coupon_discount = action.payload.coupon_discount;
            state.total = action.payload.total;
            state.shipping_fee = action.payload.shipping_fee;
            state.cartCredit = action.payload.credit_amount;
            state.cartCouponCode = action.payload.coupon_code;
            state.cart_messages = action.payload.messages
            state.status = "idle";
        });
        builder.addCase(removeFromCart.rejected, (state, action) => {
            state.status = "idle";
        });
        builder.addCase(updateCartQuantity.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(updateCartQuantity.fulfilled, (state, action) => {
            state.cartItems = action.payload.cart_items;
            state.subtotal = action.payload.subtotal;
            state.credit_discount = action.payload.credit_discount;
            state.coupon_discount = action.payload.coupon_discount;
            state.total = action.payload.total;
            state.shipping_fee = action.payload.shipping_fee;
            state.cartCredit = action.payload.credit_amount;
            state.cartCouponCode = action.payload.coupon_code;
            state.cart_messages = action.payload.messages
            state.status = "idle";
        });
        builder.addCase(updateCartQuantity.rejected, (state, action) => {
            state.status = "idle";
        });
        builder.addCase(removeAllFromCart.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(removeAllFromCart.fulfilled, (state, action) => {
            state.cartItems = action.payload.cart_items;
            state.subtotal = action.payload.subtotal;
            state.credit_discount = action.payload.credit_discount;
            state.coupon_discount = action.payload.coupon_discount;
            state.total = action.payload.total;
            state.shipping_fee = action.payload.shipping_fee;
            state.cartCredit = action.payload.credit_amount;
            state.cartCouponCode = action.payload.coupon_code;
            state.cart_messages = action.payload.messages
            state.status = "idle";
        });
        builder.addCase(removeAllFromCart.rejected, (state, action) => {
            state.status = "idle";
        });
        builder.addCase(applyCouponCode.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(applyCouponCode.fulfilled, (state, action) => {
            state.cartItems = action.payload.cart_items;
            state.subtotal = action.payload.subtotal;
            state.credit_discount = action.payload.credit_discount;
            state.coupon_discount = action.payload.coupon_discount;
            state.total = action.payload.total;
            state.shipping_fee = action.payload.shipping_fee;
            state.cartCredit = action.payload.credit_amount;
            state.cartCouponCode = action.payload.coupon_code;
            state.cart_messages = action.payload.messages
            state.status = "idle";
        });
        builder.addCase(applyCouponCode.rejected, (state, action) => {
            state.status = "idle";
        });
        builder.addCase(removeCouponCode.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(removeCouponCode.fulfilled, (state, action) => {
            state.cartItems = action.payload.cart_items;
            state.subtotal = action.payload.subtotal;
            state.credit_discount = action.payload.credit_discount;
            state.coupon_discount = action.payload.coupon_discount;
            state.total = action.payload.total;
            state.shipping_fee = action.payload.shipping_fee;
            state.cartCredit = action.payload.credit_amount;
            state.cartCouponCode = action.payload.coupon_code;
            state.cart_messages = action.payload.messages
            state.status = "idle";
        });
        builder.addCase(removeCouponCode.rejected, (state, action) => {
            state.status = "idle";
        });
        builder.addCase(applyUseCredit.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(applyUseCredit.fulfilled, (state, action) => {
            state.cartItems = action.payload.cart_items;
            state.subtotal = action.payload.subtotal;
            state.credit_discount = action.payload.credit_discount;
            state.coupon_discount = action.payload.coupon_discount;
            state.total = action.payload.total;
            state.shipping_fee = action.payload.shipping_fee;
            state.cartCredit = action.payload.credit_amount;
            state.cartCouponCode = action.payload.coupon_code;
            state.cart_messages = action.payload.messages
            state.status = "idle";
        });
        builder.addCase(applyUseCredit.rejected, (state, action) => {
            state.status = "idle";
        });
        builder.addCase(removeUseCredit.pending, (state, action) => {
            state.status = "loading";
        });
        builder.addCase(removeUseCredit.fulfilled, (state, action) => {
            state.cartItems = action.payload.cart_items;
            state.subtotal = action.payload.subtotal;
            state.credit_discount = action.payload.credit_discount;
            state.coupon_discount = action.payload.coupon_discount;
            state.total = action.payload.total;
            state.shipping_fee = action.payload.shipping_fee;
            state.cartCredit = action.payload.credit_amount;
            state.cartCouponCode = action.payload.coupon_code;
            state.cart_messages = action.payload.messages
            state.status = "idle";
        });
        builder.addCase(removeUseCredit.rejected, (state, action) => {
            state.status = "idle";
        });
    },
});

export const fetchCart = createAsyncThunk(
    "category/fetchCart",
    async (_, { getState }) => {
        const userState = getState().user;
        const cartState = getState().cart;
        const response = await axios.post(
            process.env.REACT_APP_API_URL + "/cart_get.php",
            {
                limit: 999,
                offset: 0,
                client_id: CLIENT_ID,
                access_token: userState.site_access_token,
                delivery_country_id: cartState.shippingMethod === 'delivery' ? (cartState.deliveryCountryId || '') : '',
            }
        );
        return handleErrorRes(response);
    }
);

export const addToCart = createAsyncThunk(
    "category/addToCart",
    async (product, { getState, dispatch, rejectWithValue }) => {
        try {
            console.log(product);
            const userState = getState().user;
            const cartState = getState().cart;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/cart_add_product.php",
                {
                    client_id: CLIENT_ID,
                    access_token: userState.site_access_token,
                    quantity: product.quantity ? product.quantity : 1,
                    product_id: product.id,
                    delivery_country_id: cartState.shippingMethod === 'delivery' ? (cartState.deliveryCountryId || '') : '',
                }
            );

            dispatch(setCartCredit(0));
            toastMessage(i18n.t("shop__message_added_to_cart"), "success");
            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const removeFromCart = createAsyncThunk(
    "category/removeFromCart",
    async (product, { getState, dispatch, rejectWithValue }) => {
        try {
            console.log(product);
            const userState = getState().user;
            const cartState = getState().cart;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/cart_remove_product.php",
                {
                    client_id: CLIENT_ID,
                    access_token: userState.site_access_token,
                    product_id: product.id,
                    delivery_country_id: cartState.shippingMethod === 'delivery' ? (cartState.deliveryCountryId || '') : '',
                }
            );
            dispatch(setCartCredit(0));
            toastMessage(i18n.t("shop__message_removed_from_cart"), "success");
            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const updateCartQuantity = createAsyncThunk(
    "category/updateCartQuantity",
    async (product, { getState, dispatch, rejectWithValue }) => {
        try {
            console.log(product);
            const userState = getState().user;
            const cartState = getState().cart;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/cart_update_product.php",
                {
                    client_id: CLIENT_ID,
                    access_token: userState.site_access_token,
                    product_id: product.id,
                    quantity: product.quantity,
                    delivery_country_id: cartState.shippingMethod === 'delivery' ? (cartState.deliveryCountryId || '') : '',
                }
            );
            dispatch(setCartCredit(0));
            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const removeAllFromCart = createAsyncThunk(
    "category/removeAllFromCart",
    async (_, { getState, dispatch, rejectWithValue }) => {
        try {
            const userState = getState().user;
            const cartState = getState().cart;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/cart_remove_all.php",
                {
                    client_id: CLIENT_ID,
                    access_token: userState.site_access_token,
                    delivery_country_id: cartState.shippingMethod === 'delivery' ? (cartState.deliveryCountryId || '') : '',
                }
            );
            dispatch(setCartCredit(0));
            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const setCartCreditAndFetch = createAsyncThunk(
    "cart/setCartCreditAndFetch",
    async (credit, { dispatch, getState }) => {
        dispatch(setCartCredit(credit)); // Setting the cart credit
        dispatch(fetchCart()); // Fetching the cart
    }
);

export const applyCouponCode = createAsyncThunk(
    "cart/applyCouponCode",
    async (couponCode, { dispatch, getState, rejectWithValue }) => {
        try {
            const userState = getState().user;
            const cartState = getState().cart;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/cart_coupon_code_apply.php",
                {
                    client_id: CLIENT_ID,
                    access_token: userState.site_access_token,
                    coupon_code: couponCode,
                    delivery_country_id: cartState.shippingMethod === 'delivery' ? (cartState.deliveryCountryId || '') : '',
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState.token}`,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const removeCouponCode = createAsyncThunk(
    "cart/removeCouponCode",
    async (_, { dispatch, getState, rejectWithValue }) => {
        try {
            const userState = getState().user;
            const cartState = getState().cart;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/cart_coupon_code_remove.php",
                {
                    client_id: CLIENT_ID,
                    access_token: userState.site_access_token,
                    delivery_country_id: cartState.shippingMethod === 'delivery' ? (cartState.deliveryCountryId || '') : '',
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState.token}`,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const applyUseCredit = createAsyncThunk(
    "cart/applyUseCredit",
    async (point_amount, { dispatch, getState, rejectWithValue }) => {
        try {
            const userState = getState().user;
            const cartState = getState().cart;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/cart_credit_apply.php",
                {
                    client_id: CLIENT_ID,
                    access_token: userState.site_access_token,
                    points_to_burn: [
                        {
                            point_type_id: CREDIT_POINT_TYPE,
                            amount: point_amount,
                        },
                    ],
                    delivery_country_id: cartState.shippingMethod === 'delivery' ? (cartState.deliveryCountryId || '') : '',
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState.token}`,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const removeUseCredit = createAsyncThunk(
    "cart/removeUseCredit",
    async (_, { dispatch, getState, rejectWithValue }) => {
        try {
            const userState = getState().user;
            const cartState = getState().cart;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/cart_credit_remove.php",
                {
                    client_id: CLIENT_ID,
                    access_token: userState.site_access_token,
                    delivery_country_id: cartState.shippingMethod === 'delivery' ? (cartState.deliveryCountryId || '') : '',
                },
                {
                    headers: {
                        Authorization: `Bearer ${userState.token}`,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const { setCartCredit, setCouponCode, setDeliveryCountryId, setShippingMethod } = cartSlice.actions;
export default cartSlice.reducer;
