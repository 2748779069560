import { Fragment, useState, useEffect } from "react";
import Paginator from "react-hooks-paginator";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutMain from "../../layouts/LayoutMain";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import ShopSidebar from "../../wrappers/product/ShopSidebar";
import CreditShopTopbar from "../../wrappers/product/CreditShopTopbar";
import CreditShopProducts from "../../wrappers/product/CreditShopProducts";
import {
    fetchCashCreditProducts,
    fetchCreditProducts,
} from "../../store/slices/product-slice";
import { getPointBalance } from "../../store/slices/user-slice";
import { BEANS_POINT_TYPE } from "../../helpers/config";
import ShopProducts from "../../wrappers/product/ShopProducts";
import { useTranslation } from "react-i18next";

const CreditShop = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {
        credit_products,
        total_credit_products,
        cash_credit_products,
        total_cash_credit_products,
    } = useSelector((state) => state.product);
    const { point_balances } = useSelector((state) => state.user);
    const [layout, setLayout] = useState("grid three-column");
    const [sortType, setSortType] = useState("category");
    const [sortValue, setSortValue] = useState("");
    const [filterSortType, setFilterSortType] = useState("");
    const [filterSortValue, setFilterSortValue] = useState("");
    const [shopType, setShopType] = useState("beans");
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);

    const bean_balance = point_balances[BEANS_POINT_TYPE] || 0;

    const pageLimit = 15;

    const getLayout = (layout) => {
        setLayout(layout);
    };

    const getSortParams = (sortType, sortValue) => {
        setSortType(sortType);
        setSortValue(sortValue);
    };

    const getFilterSortParams = (sortType, sortValue) => {
        setFilterSortType(sortType);
        setFilterSortValue(sortValue);
    };

    useEffect(() => {
        let sort_by = "default";
        if (filterSortType === "filterSort") {
            sort_by = filterSortValue;
        }

        if (shopType === "beans") {
            dispatch(
                fetchCreditProducts({
                    offset,
                    limit: pageLimit,
                    sort_by,
                })
            );
        } else {
            dispatch(
                fetchCashCreditProducts({
                    offset,
                    limit: pageLimit,
                    sort_by,
                })
            );
        }
    }, [
        dispatch,
        offset,
        sortType,
        sortValue,
        filterSortType,
        filterSortValue,
        shopType,
    ]);

    useEffect(() => {
        setCurrentPage(1);
        setOffset(0);
    }, [shopType]);

    useEffect(() => {
        dispatch(getPointBalance());
    }, []);

    return (
        <Fragment>
            <div className="shop-area pt-0 pb-100">
                <div className="container">
                    <h3 className="cart-page-title">{t("my_account__get_credit_page_title")}</h3>
                    <div className="row">
                        <div className="col-lg-12 order-1 order-lg-2">
                            {shopType === "beans" ? (
                                <div>
                                    {/* shop topbar default */}
                                    <CreditShopTopbar
                                        getLayout={getLayout}
                                        getFilterSortParams={
                                            getFilterSortParams
                                        }
                                        shopType={shopType}
                                        setShopType={setShopType}
                                        productCount={total_credit_products}
                                        sortedProductCount={
                                            credit_products.length
                                        }
                                    />

                                    {/* shop page content default */}
                                    <CreditShopProducts
                                        layout={layout}
                                        products={credit_products}
                                    />

                                    {/* shop product pagination */}
                                    <div className="pro-pagination-style text-center mt-30">
                                        <Paginator
                                            totalRecords={total_credit_products}
                                            pageLimit={pageLimit}
                                            pageNeighbours={2}
                                            setOffset={setOffset}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageContainerClass="mb-0 mt-0"
                                            pagePrevText="«"
                                            pageNextText="»"
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    {/* shop topbar default */}
                                    <CreditShopTopbar
                                        getLayout={getLayout}
                                        getFilterSortParams={
                                            getFilterSortParams
                                        }
                                        shopType={shopType}
                                        setShopType={setShopType}
                                        productCount={
                                            total_cash_credit_products
                                        }
                                        sortedProductCount={
                                            cash_credit_products.length
                                        }
                                    />

                                    {/* shop page content default */}
                                    <ShopProducts
                                        layout={layout}
                                        products={cash_credit_products}
                                    />

                                    {/* shop product pagination */}
                                    <div className="pro-pagination-style text-center mt-30">
                                        <Paginator
                                            totalRecords={
                                                total_cash_credit_products
                                            }
                                            pageLimit={pageLimit}
                                            pageNeighbours={2}
                                            setOffset={setOffset}
                                            currentPage={currentPage}
                                            setCurrentPage={setCurrentPage}
                                            pageContainerClass="mb-0 mt-0"
                                            pagePrevText="«"
                                            pageNextText="»"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CreditShop;
