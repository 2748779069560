import { useMemo } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useLang from "../../hooks/use-lang";
import { useTranslation } from "react-i18next";

export default function OrderDetail({ orderDetail }) {
    const currency = useSelector((state) => state.currency);
    const { t } = useTranslation();
    const { getLangValue } = useLang();

    const getProductSubtotal = (product) => {
        return parseFloat(product.sale_price) * parseFloat(product.quantity);
    };

    const cartTotalPrice = useMemo(() => {
        if (!orderDetail) return 0;

        let total = 0;
        orderDetail?.order_products.forEach((p) => {
            total += getProductSubtotal(p);
        });

        return total;
    }, [orderDetail]);

    if (!orderDetail) {
        return (
            <div className="text-center">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">
                        {t("common__loading")}...
                    </span>
                </Spinner>
            </div>
        );
    }

    return (
        <section className="order-detail-area">
            <div className="row">
                <div className="col-md-6">
                    <h2 className="mb-10">
                        <span className="fw-bold">
                            {t("order_confirmation__order_no")}:{" "}
                        </span>
                        <span className="order-no-text">
                            {orderDetail?.order_number}
                        </span>
                    </h2>
                </div>
                <div className="col-md-6 text-md-end">
                    <h2 className="mb-10">
                        <span className="fw-bold">
                            {t("order_confirmation__order_date")}:{" "}
                        </span>
                        <span className="order-date-text">
                            {orderDetail?.created_at}
                        </span>
                    </h2>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 text-md-end">
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("order_confirmation__payment_method")}:{" "}
                        </span>
                        <span className="order-payment-method order-payment-method-paypal">
                            {t("order_confirmation__credit_card")}
                        </span>
                    </p>
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("order_confirmation__status")}:{" "}
                        </span>
                        <span
                            className={`order-payment-status order-payment-status-paid text-success ${
                                orderDetail?.order_status !== "paid" &&
                                orderDetail?.order_status !== "completed"
                                    ? "d-none"
                                    : ""
                            }`}
                        >
                            {t("order_confirmation__status_completed")}
                        </span>
                        <span
                            className={`order-payment-status order-payment-status-pending text-warning ${
                                orderDetail?.order_status !== "pending"
                                    ? "d-none"
                                    : ""
                            }`}
                        >
                            {t("order_confirmation__status_pending")}
                        </span>
                        <span
                            className={`order-payment-status order-payment-status-cancel text-danger ${
                                orderDetail?.order_status !== "cancelled"
                                    ? "d-none"
                                    : ""
                            }`}
                        >
                            {t("order_confirmation__status_canceled")}
                        </span>
                    </p>
                </div>
            </div>

            <div className="row mt-40">
                <div className="col-12">
                    <div className="table-content table-responsive cart-table-content">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        {t(
                                            "order_confirmation__table_head_image"
                                        )}
                                    </th>
                                    <th>
                                        {t(
                                            "order_confirmation__table_head_product_name"
                                        )}
                                    </th>
                                    <th>
                                        {t(
                                            "order_confirmation__table_head_unit_price"
                                        )}
                                    </th>
                                    <th>
                                        {t(
                                            "order_confirmation__table_head_quantity"
                                        )}
                                    </th>
                                    <th>
                                        {t(
                                            "order_confirmation__table_head_subtotal"
                                        )}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {orderDetail?.order_products.map(
                                    (product, key) => {
                                        return (
                                            <tr key={key}>
                                                <td className="product-thumbnail">
                                                    <Link
                                                        to={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/product/" +
                                                            product.product_id
                                                        }
                                                    >
                                                        <img
                                                            className="img-fluid"
                                                            src={
                                                                product.image[0]
                                                            }
                                                            alt=""
                                                        />
                                                    </Link>
                                                </td>

                                                <td className="product-name text-center">
                                                    <Link
                                                        to={
                                                            process.env
                                                                .PUBLIC_URL +
                                                            "/product/" +
                                                            product.product_id
                                                        }
                                                    >
                                                        <div>
                                                            {getLangValue(
                                                                product,
                                                                "product_name"
                                                            )}
                                                        </div>
                                                        <span>
                                                            {t(
                                                                "order_confirmation__sku"
                                                            )}
                                                            :{" "}
                                                        </span>
                                                        <span>
                                                            {
                                                                product.product_sku
                                                            }
                                                        </span>
                                                        <div className="cart-item-variation">
                                                            {getLangValue(
                                                                product,
                                                                "variant_value_1"
                                                            ) && (
                                                                <span
                                                                    className="small"
                                                                    style={{
                                                                        color: "#6c757d",
                                                                    }}
                                                                >
                                                                    {getLangValue(
                                                                        product,
                                                                        "variant_name_1"
                                                                    )}
                                                                    :{" "}
                                                                    {getLangValue(
                                                                        product,
                                                                        "variant_value_1"
                                                                    )}
                                                                </span>
                                                            )}
                                                            {getLangValue(
                                                                product,
                                                                "variant_value_2"
                                                            ) && (
                                                                <span
                                                                    className="small"
                                                                    style={{
                                                                        color: "#6c757d",
                                                                    }}
                                                                >
                                                                    {getLangValue(
                                                                        product,
                                                                        "variant_name_2"
                                                                    )}
                                                                    :{" "}
                                                                    {getLangValue(
                                                                        product,
                                                                        "variant_value_2"
                                                                    )}
                                                                </span>
                                                            )}

                                                            {getLangValue(
                                                                product,
                                                                "variant_value_3"
                                                            ) && (
                                                                <span
                                                                    className="small"
                                                                    style={{
                                                                        color: "#6c757d",
                                                                    }}
                                                                >
                                                                    {getLangValue(
                                                                        product,
                                                                        "variant_name_3"
                                                                    )}
                                                                    :{" "}
                                                                    {getLangValue(
                                                                        product,
                                                                        "variant_value_3"
                                                                    )}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Link>
                                                </td>

                                                <td className="product-price-cart">
                                                    <span className="amount">
                                                        {currency.currencySymbol +
                                                            product.sale_price}
                                                    </span>
                                                </td>

                                                <td className="product-quantity-cart">
                                                    <span>
                                                        {product.quantity}
                                                    </span>
                                                </td>

                                                <td className="product-subtotal">
                                                    <span className="amount">
                                                        {currency.currencySymbol +
                                                            getProductSubtotal(
                                                                product
                                                            )}
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    }
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {orderDetail?.delivery_method === "delivery" && (
                <div className="row mt-40">
                    <h4>{t("order_confirmation__shipping_info")}</h4>
                    <div className="col-md-6">
                        <p className="mb-10">
                            <span className="font-weight-bold">
                                {t("form__first_name")}:{" "}
                            </span>
                            <span className="order-name_1-text">
                                {orderDetail?.first_name2 || "-"}
                            </span>
                        </p>
                        <p className="mb-10">
                            <span className="font-weight-bold">
                                {t("form__last_name")}:{" "}
                            </span>
                            <span className="order-name_2-text">
                                {orderDetail?.last_name2 || "-"}
                            </span>
                        </p>
                        <p className="mb-10">
                            <span className="font-weight-bold">
                                {t("form__address")}:{" "}
                            </span>
                            <span className="order-address-text">
                                {orderDetail?.address2 || "-"}
                            </span>
                        </p>
                        <p className="mb-10">
                            <span className="font-weight-bold">
                                {t("form__company_name")}:{" "}
                            </span>
                            <span className="order-company-text">
                                {orderDetail?.company_name2 || "-"}
                            </span>
                        </p>
                        <p className="mb-10">
                            <span className="font-weight-bold">
                                {t("form__phone")}:{" "}
                            </span>
                            <span className="order-phone-text">
                                {orderDetail?.phone2 || "-"}
                            </span>
                        </p>
                    </div>
                    <div className="col-md-6">
                        <p className="mb-10">
                            <span className="font-weight-bold">
                                {t("form__country")}:{" "}
                            </span>
                            <span className="order-country-text">
                                {orderDetail?.country_name2 || "-"}
                            </span>
                        </p>
                        <p className="mb-10">
                            <span className="font-weight-bold">
                                {t("form__city")}:{" "}
                            </span>
                            <span className="order-city-text">
                                {orderDetail?.city2 || "-"}
                            </span>
                        </p>
                        <p className="mb-10">
                            <span className="font-weight-bold">
                                {t("form__state")}:{" "}
                            </span>
                            <span className="order-state-text">
                                {orderDetail?.state2 || "-"}
                            </span>
                        </p>
                        <p className="mb-10">
                            <span className="font-weight-bold">
                                {t("form__zip_code")}:{" "}
                            </span>
                            <span className="order-zip_code-text">
                                {orderDetail?.postal_code2 || "-"}
                            </span>
                        </p>
                        <p className="mb-10">
                            <span className="font-weight-bold">
                                {t("form__email")}:{" "}
                            </span>
                            <span className="order-email-text">
                                {orderDetail?.email2 || "-"}
                            </span>
                        </p>
                    </div>
                </div>
            )}

            <div className="row mt-40">
                <h4>{t("order_confirmation__billing_info")}</h4>
                <div className="col-md-6">
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("form__first_name")}:{" "}
                        </span>
                        <span className="order-name_1-text">
                            {orderDetail?.first_name || "-"}
                        </span>
                    </p>
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("form__last_name")}:{" "}
                        </span>
                        <span className="order-name_2-text">
                            {orderDetail?.last_name || "-"}
                        </span>
                    </p>
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("form__address")}:{" "}
                        </span>
                        <span className="order-address-text">
                            {orderDetail?.address || "-"}
                        </span>
                    </p>
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("form__company_name")}:{" "}
                        </span>
                        <span className="order-company-text">
                            {orderDetail?.company_name || "-"}
                        </span>
                    </p>
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("form__phone")}:{" "}
                        </span>
                        <span className="order-phone-text">
                            {orderDetail?.phone || "-"}
                        </span>
                    </p>
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("form__additional_information")}:{" "}
                        </span>
                        <span className="order-additional_info-text">
                            {orderDetail?.additional_info || "-"}
                        </span>
                    </p>
                </div>
                <div className="col-md-6">
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("form__country")}:{" "}
                        </span>
                        <span className="order-country-text">
                            {orderDetail?.country_name || "-"}
                        </span>
                    </p>
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("form__city")}:{" "}
                        </span>
                        <span className="order-city-text">
                            {orderDetail?.city || "-"}
                        </span>
                    </p>
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("form__state")}:{" "}
                        </span>
                        <span className="order-state-text">
                            {orderDetail?.state || "-"}
                        </span>
                    </p>
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("form__zip_code")}:{" "}
                        </span>
                        <span className="order-zip_code-text">
                            {orderDetail?.postal_code || "-"}
                        </span>
                    </p>
                    <p className="mb-10">
                        <span className="font-weight-bold">
                            {t("form__email")}:{" "}
                        </span>
                        <span className="order-email-text">
                            {orderDetail?.email || "-"}
                        </span>
                    </p>
                </div>
            </div>

            <div className="row mt-40">
                <div className="col-lg-6 col-md-12 ms-auto">
                    <div className="grand-totall">
                        <div className="title-wrap">
                            <h4 className="cart-bottom-title section-bg-gary-cart">
                                {t("order_confirmation__order_total")}
                            </h4>
                        </div>
                        <h5>
                            {t("order_confirmation__total_products")}{" "}
                            <span>
                                {currency.currencySymbol +
                                    parseFloat(
                                        orderDetail?.subtotal_amount
                                    ).toFixed(2)}
                            </span>
                        </h5>

                        <h5>
                            {t("order_confirmation__total_discount")}{" "}
                            <span>
                                -
                                {currency.currencySymbol +
                                    parseFloat(
                                        orderDetail?.discount_amount
                                    ).toFixed(2)}
                            </span>
                        </h5>

                        <h5>
                            {t("order_confirmation__shipping_fee")}{" "}
                            <span>
                                {currency.currencySymbol +
                                    parseFloat(
                                        orderDetail?.shipping_fee
                                    ).toFixed(2)}
                            </span>
                        </h5>

                        <h4 className="grand-totall-title">
                            {t("order_confirmation__grand_total")}{" "}
                            <span>
                                {currency.currencySymbol +
                                    parseFloat(
                                        orderDetail?.total_amount
                                    ).toFixed(2)}
                            </span>
                        </h4>

                        <h5>
                            {t("order_confirmation__credit_used")}{" "}
                            <span>
                                -
                                {currency.currencySymbol +
                                    parseFloat(
                                        orderDetail?.total_point_to_cash_value
                                    ).toFixed(2)}
                            </span>
                        </h5>

                        <h4 className="grand-totall-title">
                            {t("order_confirmation__total_cash_paid")}{" "}
                            <span>
                                {currency.currencySymbol +
                                    parseFloat(
                                        orderDetail?.cash_amount
                                    ).toFixed(2)}
                            </span>
                        </h4>
                    </div>
                </div>
            </div>
        </section>
    );
}
