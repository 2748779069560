import PropTypes from "prop-types";

import { setActiveSort } from "../../helpers/product";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useLang from "../../hooks/use-lang";

const ShopCategories = ({ getSortParams }) => {
    const { categories } = useSelector((state) => state.category);
    const { category_id } = useParams();
    const { getLangValue } = useLang();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const primary_cat_id = useMemo(() => {
        for (var i = 0; i < categories.length; i++) {
            if (categories[i].id === category_id) {
                return category_id;
            }

            for (var j = 0; j < categories[i].sub_categories.length; j++) {
                if (categories[i].sub_categories[j].id === category_id) {
                    return categories[i].sub_categories[j]
                        .parent_primary_category_id;
                }
            }
        }

        return null;
    }, [categories, category_id]);

    return (
        <div className="sidebar-widget">
            <h4 className="pro-sidebar-title">{t("shop__filter_category")} </h4>
            <div className="sidebar-widget-list mt-30">
                {categories ? (
                    <ul>
                        <li>
                            <div className="sidebar-widget-list-left">
                                <button
                                    className={
                                        !category_id ? "ps-0 active" : "ps-0"
                                    }
                                    onClick={(e) => {
                                        // getSortParams("category", "");
                                        setActiveSort(e);
                                        navigate(
                                            process.env.PUBLIC_URL + "/shop"
                                        );
                                    }}
                                >
                                    {/* <span className="checkmark" /> */}
                                    {t("shop__all_categories")}
                                </button>
                            </div>
                        </li>
                        {categories.map((category, key) => {
                            return (
                                <React.Fragment key={key}>
                                    <li>
                                        <div className="sidebar-widget-list-left">
                                            <button
                                                className={
                                                    category_id ===
                                                        category.id ||
                                                    primary_cat_id ===
                                                        category.id
                                                        ? "ps-0 active"
                                                        : "ps-0"
                                                }
                                                onClick={(e) => {
                                                    // getSortParams(
                                                    //     "category",
                                                    //     category
                                                    // );
                                                    setActiveSort(e);
                                                    navigate(
                                                        process.env.PUBLIC_URL +
                                                            "/shop/" +
                                                            category.id
                                                    );
                                                }}
                                            >
                                                {/* {" "}
                                                <span className="checkmark" />{" "} */}
                                                {getLangValue(
                                                    category,
                                                    "category_name"
                                                )}{" "}
                                            </button>
                                        </div>
                                    </li>
                                </React.Fragment>
                            );
                        })}
                    </ul>
                ) : (
                    t("shop__category_not_found")
                )}
            </div>
        </div>
    );
};

ShopCategories.propTypes = {
    categories: PropTypes.array,
    getSortParams: PropTypes.func,
};

export default ShopCategories;
