import PropTypes from "prop-types";
import { useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import HeaderTop from "../../components/header/HeaderTop";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategories } from "../../store/slices/category-slice";
import { useTranslation } from "react-i18next";
import useLang from "../../hooks/use-lang";

const HeaderMain = ({
    layout,
    top,
    borderStyle,
    headerPaddingClass,
    headerPositionClass,
    headerBgClass,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { getLangValue } = useLang();
    const { categories } = useSelector((state) => state.category);
    const [scroll, setScroll] = useState(0);
    const [headerTop, setHeaderTop] = useState(0);

    const navMenus = useMemo(() => {
        return [
            {
                label: t("nav_menu__about"),
                to: "/about",
                sub_menus: [],
            },
            {
                label: t("nav_menu__shop"),
                to: "/shop",
                groups: categories.map((category, ci) => ({
                    label: getLangValue(category, "category_name"),
                    to: `/shop/${category.id}`,
                    group_items: category.sub_categories.map(
                        (sub_category, sci) => ({
                            label: getLangValue(sub_category, "category_name"),
                            to: `/shop/${sub_category.id}`,
                        })
                    ),
                })),
            },
            {
                label: t("nav_menu__subscription"),
                to: "/subscription",
                groups: [],
            },
            {
                label: t("nav_menu__locations"),
                to: "/locations",
                groups: [],
            },
            {
                label: t("nav_menu__like_a_champ"),
                to: "/like-a-champ",
                groups: [],
            },
            {
                label: t("nav_menu__corporate"),
                to: "/corporate-corner",
                groups: [],
            },
        ];
    }, [t, categories]);

    useEffect(() => {
        const header = document.querySelector(".sticky-bar");
        setHeaderTop(header.offsetTop);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        dispatch(fetchCategories());
    }, []);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    return (
        <header
            className={clsx(
                "header-area clearfix",
                headerBgClass,
                headerPositionClass
            )}
        >
            <div
                className={clsx(
                    "header-top-area",
                    headerPaddingClass,
                    top === "visible" ? "d-none d-lg-block" : "d-none",
                    borderStyle === "fluid-border" && "border-none"
                )}
            >
                <div
                    className={
                        layout === "container-fluid" ? layout : "container"
                    }
                >
                    {/* header top */}
                    <HeaderTop borderStyle={borderStyle} />
                </div>
            </div>

            <div
                className={clsx(
                    headerPaddingClass,
                    "sticky-bar header-res-padding clearfix",
                    scroll > headerTop && "stick"
                )}
            >
                <div
                    className={
                        layout === "container-fluid" ? layout : "container"
                    }
                >
                    {/* <div className="row">
                        <div className="col-xl-2 col-lg-2 col-md-6 col-4">
                            <Logo
                                imageUrl="/assets/img/logo/logo.png"
                                logoClass="logo"
                            />
                        </div>
                        <div className="col-xl-8 col-lg-8 d-none d-lg-block">
                            <NavMenu navMenus={navMenus} />
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-6 col-8">
                            <IconGroup />
                        </div>
                    </div> */}
                    <div className="d-flex align-items-center justify-content-between gap-2">
                        <Logo
                            imageUrl="/assets/img/logo/logo.png"
                            logoClass="logo mt-0"
                        />
                        <div className="d-none d-lg-block">
                            <NavMenu navMenus={navMenus} />
                        </div>
                        <IconGroup />
                    </div>
                </div>
                {/* mobile menu */}
                <MobileMenu />
            </div>
        </header>
    );
};

HeaderMain.propTypes = {
    borderStyle: PropTypes.string,
    headerPaddingClass: PropTypes.string,
    headerPositionClass: PropTypes.string,
    layout: PropTypes.string,
    top: PropTypes.string,
};

export default HeaderMain;
