import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { getDiscountPrice, getDiscountPrice2 } from "../../helpers/product";
import Rating from "./sub-components/ProductRating";
import ProductModal from "./ProductModal";
import { addToCart } from "../../store/slices/cart-slice";
import {
    addToWishlist,
    deleteFromWishlist,
} from "../../store/slices/wishlist-slice";
import { addToCompare } from "../../store/slices/compare-slice";
import ImageLoader from "../ImageLoader";
import { useTranslation } from "react-i18next";
import useLang from "../../hooks/use-lang";

const ProductGridListSingle = ({
    product,
    currency,
    cartItem,
    wishlistItem,
    compareItem,
    spaceBottomClass,
}) => {
    const { t } = useTranslation();
    const {getLangValue} = useLang();
    const [modalShow, setModalShow] = useState(false);
    const [descExpanded, setDescExpanded] = useState(true);

    let _product = product.variants?.length > 0 ? product.variants[0] : product;

    const showSoldOutTag =
        product.variants &&
        product.variants.length <= 1 &&
        parseInt(_product.inventory) === 0;

    const discountedPrice = getDiscountPrice2(
        _product.sale_price,
        _product.discount
    );
    const finalProductPrice = +(
        _product.retail_price * currency.currencyRate
    ).toFixed(2);
    const finalDiscountedPrice = +(
        discountedPrice * currency.currencyRate
    ).toFixed(2);
    const dispatch = useDispatch();

    return (
        <Fragment>
            <div className={clsx("product-wrap", spaceBottomClass)}>
                <div className="product-img">
                    <Link
                        to={process.env.PUBLIC_URL + "/product/" + product.id}
                    >
                        {/* <img
                            className="default-img"
                            src={_product.image?.[0]}
                            alt={product.product_name_en}
                        /> */}
                        <ImageLoader
                            className="default-img"
                            src={_product.image?.[0]}
                            alt={product.product_name_en}
                        />
                        {_product.image?.length > 1 ? (
                            // <img
                            //     className="hover-img"
                            //     src={_product.image?.[1]}
                            //     alt={product.product_name_en}
                            // />
                            <ImageLoader
                                className="hover-img"
                                src={_product.image?.[1]}
                                alt={product.product_name_en}
                            />
                        ) : (
                            ""
                        )}
                    </Link>
                    {_product.discount || _product.new || showSoldOutTag ? (
                        <div className="product-img-badges">
                            {_product.discount ? (
                                <span className="pink">
                                    -{_product.discount.toFixed(0)}%
                                </span>
                            ) : (
                                ""
                            )}
                            {_product.new ? (
                                <span className="purple">
                                    {t("shop__tag_new")}
                                </span>
                            ) : (
                                ""
                            )}
                            {showSoldOutTag ? (
                                <span className="purple">
                                    {t("shop__tag_sold_out")}
                                </span>
                            ) : (
                                ""
                            )}
                        </div>
                    ) : (
                        ""
                    )}

                    <div className="product-action">
                        <div className="pro-same-action pro-wishlist">
                            <button
                                className={
                                    wishlistItem !== undefined ? "active" : ""
                                }
                                disabled={wishlistItem !== undefined && false}
                                title={
                                    wishlistItem !== undefined
                                        ? t("shop__tooltip_remove_wishlist")
                                        : t("shop__tooltip_add_wishlist")
                                }
                                onClick={() => {
                                    if (wishlistItem) {
                                        dispatch(
                                            deleteFromWishlist(wishlistItem.id)
                                        );
                                    } else {
                                        dispatch(addToWishlist(product));
                                    }
                                }}
                            >
                                <i
                                    className={`fa ${
                                        wishlistItem !== undefined
                                            ? "fa-heart"
                                            : "fa-heart"
                                    }`}
                                />
                            </button>
                        </div>
                        <div className="pro-same-action pro-cart">
                            {product.affiliateLink ? (
                                <a
                                    href={product.affiliateLink}
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    {" "}
                                    Buy now{" "}
                                </a>
                            ) : product.variation &&
                              product.variation.length >= 1 ? (
                                <Link
                                    to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                                >
                                    {t("shop__add_to_cart")}
                                </Link>
                            ) : product.variants &&
                              product.variants.length >= 1 ? (
                                <Link
                                    to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                                >
                                    {t("shop__add_to_cart")}
                                </Link>
                            ) : _product.inventory && _product.inventory > 0 ? (
                                <button
                                    onClick={() =>
                                        dispatch(addToCart(_product))
                                    }
                                    className={
                                        cartItem !== undefined &&
                                        cartItem.quantity > 0
                                            ? "active"
                                            : ""
                                    }
                                    disabled={
                                        cartItem !== undefined &&
                                        cartItem.quantity > 0
                                    }
                                    title={
                                        cartItem !== undefined
                                            ? t("shop__tooltip_remove_cart")
                                            : t("shop__tooltip_add_cart")
                                    }
                                >
                                    {" "}
                                    <i className="pe-7s-cart"></i>{" "}
                                    {cartItem !== undefined &&
                                    cartItem.quantity > 0
                                        ? t("shop__tooltip_remove_cart")
                                        : t("shop__tooltip_add_cart")}
                                </button>
                            ) : (
                                <button disabled className="active">
                                    {t("Out of Stock")}
                                </button>
                            )}
                        </div>
                        <div className="pro-same-action pro-quickview">
                            <button
                                onClick={() => setModalShow(true)}
                                title={t("shop__quick_view")}
                            >
                                <i className="pe-7s-look" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="product-content text-center">
                    <h3>
                        <Link
                            to={
                                process.env.PUBLIC_URL +
                                "/product/" +
                                product.id
                            }
                        >
                            {getLangValue(product, "product_name")}
                        </Link>
                    </h3>
                    {product.rating && product.rating > 0 ? (
                        <div className="product-rating">
                            {/* <Rating ratingValue={product.rating} /> */}
                        </div>
                    ) : (
                        ""
                    )}
                    <div className="product-price">
                        {discountedPrice !== null ? (
                            <Fragment>
                                <span>
                                    {currency.currencySymbol +
                                        (finalDiscountedPrice).toLocaleString()}
                                </span>{" "}
                                <span className="old">
                                    {currency.currencySymbol +
                                        (finalProductPrice).toLocaleString()}
                                </span>
                            </Fragment>
                        ) : (
                            <span>
                                {currency.currencySymbol + (finalProductPrice).toLocaleString()}{" "}
                            </span>
                        )}
                    </div>
                </div>
            </div>
            <div className="shop-list-wrap mb-30">
                <div className="row">
                    <div className="col-xl-4 col-md-5 col-sm-6">
                        <div className="product-list-image-wrap">
                            <div className="product-img">
                                <Link
                                    to={
                                        process.env.PUBLIC_URL +
                                        "/product/" +
                                        product.id
                                    }
                                >
                                    {/* <img
                                        className="default-img img-fluid"
                                        src={_product.image?.[0]}
                                        alt={product.product_name_en}
                                    /> */}
                                    <ImageLoader
                                        className="default-img img-fluid"
                                        src={_product.image?.[0]}
                                        alt={product.product_name_en}
                                    />
                                    {_product.image?.length > 1 ? (
                                        // <img
                                        //     className="hover-img img-fluid"
                                        //     src={_product.image?.[1]}
                                        //     alt={product.product_name_en}
                                        // />
                                        <ImageLoader
                                            className="hover-img img-fluid"
                                            src={_product.image?.[1]}
                                            alt={product.product_name_en}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Link>
                                {_product.discount ||
                                _product.new ||
                                showSoldOutTag ? (
                                    <div className="product-img-badges">
                                        {_product.discount ? (
                                            <span className="pink">
                                                -{_product.discount.toFixed(0)}%
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                        {_product.new ? (
                                            <span className="purple">{t("shop__tag_new")}</span>
                                        ) : (
                                            ""
                                        )}

                                        {showSoldOutTag ? (
                                            <span className="purple">
                                                {t("shop__tag_sold_out")}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-md-7 col-sm-6">
                        <div className="shop-list-content">
                            <h3>
                                <Link
                                    to={
                                        process.env.PUBLIC_URL +
                                        "/product/" +
                                        product.id
                                    }
                                >
                                    {getLangValue(product, "product_name")}
                                </Link>
                            </h3>
                            <div className="product-list-price">
                                {discountedPrice !== null ? (
                                    <Fragment>
                                        <span>
                                            {currency.currencySymbol +
                                                (finalDiscountedPrice).toLocaleString()}
                                        </span>{" "}
                                        <span className="old">
                                            {currency.currencySymbol +
                                                (finalProductPrice).toLocaleString()}
                                        </span>
                                    </Fragment>
                                ) : (
                                    <span>
                                        {currency.currencySymbol +
                                            (finalProductPrice).toLocaleString()}{" "}
                                    </span>
                                )}
                            </div>
                            {product.rating && product.rating > 0 ? (
                                <div className="rating-review">
                                    <div className="product-list-rating">
                                        <Rating ratingValue={product.rating} />
                                    </div>
                                </div>
                            ) : (
                                ""
                            )}
                            {getLangValue(product, "product_description") ? (
                                <div style={{ marginBottom: 30 }}>
                                    <p
                                        style={{
                                            height: descExpanded
                                                ? null
                                                : 28 * 5,
                                            overflow: descExpanded
                                                ? null
                                                : "hidden",
                                            marginBlock: 10,
                                        }}
                                        dangerouslySetInnerHTML={{
                                            __html: getLangValue(product, "product_description"),
                                        }}
                                    ></p>
                                    {/* <a
                                        href="#"
                                        
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setDescExpanded((ex) => !ex);
                                        }}
                                    >
                                        {descExpanded
                                            ? "Read less"
                                            : "Read more"}
                                    </a> */}
                                </div>
                            ) : (
                                ""
                            )}

                            <div className="shop-list-actions d-flex align-items-center">
                                <div className="shop-list-btn btn-hover">
                                    {product.affiliateLink ? (
                                        <a
                                            href={product.affiliateLink}
                                            rel="noopener noreferrer"
                                            target="_blank"
                                        >
                                            {" "}
                                            Buy now{" "}
                                        </a>
                                    ) : product.variation &&
                                      product.variation.length >= 1 ? (
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                                        >
                                            {t("shop__add_to_cart")}
                                        </Link>
                                    ) : product.variants &&
                                      product.variants.length >= 1 ? (
                                        <Link
                                            to={`${process.env.PUBLIC_URL}/product/${product.id}`}
                                        >
                                            {t("shop__add_to_cart")}
                                        </Link>
                                    ) : _product.inventory &&
                                      _product.inventory > 0 ? (
                                        <button
                                            onClick={() =>
                                                dispatch(addToCart(product))
                                            }
                                            className={
                                                cartItem !== undefined &&
                                                cartItem.quantity > 0
                                                    ? "active"
                                                    : ""
                                            }
                                            disabled={
                                                cartItem !== undefined &&
                                                cartItem.quantity > 0
                                            }
                                            title={
                                                cartItem !== undefined
                                                    ? t("shop__tooltip_remove_cart")
                                                    : t("shop__tooltip_add_cart")
                                            }
                                        >
                                            {" "}
                                            <i className="pe-7s-cart"></i>{" "}
                                            {cartItem !== undefined &&
                                            cartItem.quantity > 0
                                                ? t("shop__tooltip_remove_cart")
                                                : t("shop__tooltip_add_cart")}
                                        </button>
                                    ) : (
                                        <button disabled className="active">
                                            {t("shop__out_of_stock")}
                                        </button>
                                    )}
                                </div>

                                <div className="shop-list-wishlist ml-10">
                                    <button
                                        className={
                                            wishlistItem !== undefined
                                                ? "active"
                                                : ""
                                        }
                                        disabled={
                                            wishlistItem !== undefined && false
                                        }
                                        title={
                                            wishlistItem !== undefined
                                                ? t("shop__tooltip_remove_wishlist")
                                                : t("shop__tooltip_add_wishlist")
                                        }
                                        onClick={() => {
                                            if (wishlistItem) {
                                                dispatch(
                                                    deleteFromWishlist(
                                                        wishlistItem.id
                                                    )
                                                );
                                            } else {
                                                dispatch(
                                                    addToWishlist(product)
                                                );
                                            }
                                        }}
                                    >
                                        <i className="pe-7s-like" />
                                    </button>
                                </div>
                                {/* <div className="shop-list-compare ml-10">
                                    <button
                                        className={
                                            compareItem !== undefined
                                                ? "active"
                                                : ""
                                        }
                                        disabled={compareItem !== undefined}
                                        title={
                                            compareItem !== undefined
                                                ? "Added to compare"
                                                : "Add to compare"
                                        }
                                        onClick={() =>
                                            dispatch(addToCompare(product))
                                        }
                                    >
                                        <i className="pe-7s-shuffle" />
                                    </button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* product modal */}
            <ProductModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                product={product}
                currency={currency}
                discountedPrice={discountedPrice}
                finalProductPrice={finalProductPrice}
                finalDiscountedPrice={finalDiscountedPrice}
                wishlistItem={wishlistItem}
                compareItem={compareItem}
            />
        </Fragment>
    );
};

ProductGridListSingle.propTypes = {
    cartItem: PropTypes.shape({}),
    compareItem: PropTypes.shape({}),
    currency: PropTypes.shape({}),
    product: PropTypes.shape({}),
    spaceBottomClass: PropTypes.string,
    wishlistItem: PropTypes.shape({}),
};

export default ProductGridListSingle;
