import { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import useLang from "../../hooks/use-lang";
import { BEANS_POINT_TYPE } from "../../helpers/config";
import { pointTransfer } from "../../store/slices/user-slice";
import { toastMessage } from "../../helpers/utils";
import { useTranslation } from "react-i18next";

function CreditProductConfirmModal({ product, show, onHide }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { getLangValue } = useLang();

    const { point_balances, point_transfer_status } = useSelector(
        (state) => state.user
    );
    const bean_balance = point_balances[BEANS_POINT_TYPE] || 0;
    const price = product?.from_amount;
    const credit = product?.to_amount || 0;

    const [quantity, setQuantity] = useState(0);
    const [balance, setBalance] = useState(bean_balance);

    useEffect(() => {
        if (show) {
            setQuantity(1);
            setBalance(bean_balance);
        }
    }, [show]);

    const handleIncrease = () => {
        if ((quantity + 1) * price <= bean_balance) {
            setQuantity((prevQuantity) => prevQuantity + 1);
            setBalance(bean_balance - (quantity + 1) * price);
        }
    };

    const handleDecrease = () => {
        if (quantity > 0) {
            setQuantity((prevQuantity) => prevQuantity - 1);
            setBalance(bean_balance - (quantity - 1) * price);
        }
    };

    const onCloseModal = () => {
        onHide();
    };

    const onConfirm = () => {
        dispatch(
            pointTransfer({
                from_amount: product?.from_amount * quantity,
                onHideModal: onHide,
            })
        );
    };

    if (!product) return null;

    return (
        <Modal
            show={show}
            onHide={onCloseModal}
            size="sm"
            dialogClassName="credit-confirm-modal-wrapper"
            backdrop="static"
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title>
                    {t("my_account__get_credit_transfer_title", { credit })}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <div style={styles.pointContainer}>
                    <span>
                        {t("my_account__get_credit_transfer_beans_price", {
                            price,
                        })}
                    </span>
                    <span>
                        {t("my_account__get_credit_transfer_beans_available", {
                            balance,
                        })}
                    </span>
                </div>
                <div style={styles.quantityContainer}>
                    <Button
                        onClick={handleDecrease}
                        style={styles.buttonStyle}
                        disabled={point_transfer_status !== "idle"}
                    >
                        -
                    </Button>
                    <span style={styles.quantityValue}>{quantity}</span>
                    <Button
                        onClick={handleIncrease}
                        style={styles.buttonStyle}
                        disabled={point_transfer_status !== "idle"}
                    >
                        +
                    </Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={onHide}
                    disabled={point_transfer_status !== "idle"}
                >
                    {t("common__cancel")}
                </Button>
                <Button
                    variant="primary"
                    onClick={onConfirm}
                    style={styles.buttonStyle}
                    disabled={point_transfer_status !== "idle" || quantity <= 0}
                >
                    {point_transfer_status === "loading" ? (
                        <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span className="sr-only">
                                {t("common__loading")}...
                            </span>
                        </>
                    ) : (
                        t("common__confirm")
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

CreditProductConfirmModal.propTypes = {
    currency: PropTypes.shape({}),
    onHide: PropTypes.func,
    product: PropTypes.shape({}),
    show: PropTypes.bool,
};

const styles = {
    buttonStyle: {
        backgroundColor: "#70898B",
        borderColor: "#70898B",
    },
    pointContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    quantityContainer: {
        display: "flex",
        // justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
    },
    quantityValue: {
        margin: "0 10px",
        width: 30,
        textAlign: "center",
    },
};

export default CreditProductConfirmModal;
