import { Fragment, forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getDiscountPrice, getDiscountPrice2 } from "../../../helpers/product";
import { removeFromCart, fetchCart } from "../../../store/slices/cart-slice";
import useLang from "../../../hooks/use-lang";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const MenuCart = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const currency = useSelector((state) => state.currency);
    const {
        cartItems,
        status: cart_status,
        coupon_discount,
        credit_discount,
        shipping_fee,
        total,
        deliveryCountryId,
    } = useSelector((state) => state.cart);
    const { site_access_token } = useSelector((state) => state.user);
    const { getLangValue } = useLang();
    const { t } = useTranslation();
    let cartTotalPrice = 0;

    useEffect(() => {
        if (site_access_token) {
            dispatch(fetchCart());
        }
    }, [site_access_token, dispatch]);

    if (cart_status === "loading") {
        return (
            <div className="shopping-cart-content" ref={ref}>
                <div className="text-center pt-50 pb-50">
                    <Spinner />
                </div>
            </div>
        );
    }

    return (
        <div className="shopping-cart-content" ref={ref}>
            {cartItems && cartItems.length > 0 ? (
                <Fragment>
                    <ul>
                        {cartItems.map((item, key) => {
                            const discountedPrice = getDiscountPrice2(
                                item.sale_price,
                                item.discount
                            );
                            const finalProductPrice = (
                                item.retail_price * currency.currencyRate
                            ).toFixed(2);
                            const finalDiscountedPrice = (
                                discountedPrice * currency.currencyRate
                            ).toFixed(2);

                            discountedPrice != null
                                ? (cartTotalPrice +=
                                      finalDiscountedPrice * item.quantity)
                                : (cartTotalPrice +=
                                      finalProductPrice * item.quantity);
                            return (
                                <li className="single-shopping-cart" key={key}>
                                    <div className="shopping-cart-img">
                                        <Link
                                            to={
                                                process.env.PUBLIC_URL +
                                                "/product/" +
                                                item.id
                                            }
                                        >
                                            <img
                                                alt=""
                                                src={item.image[0]}
                                                className="img-fluid"
                                            />
                                        </Link>
                                    </div>
                                    <div className="shopping-cart-title">
                                        <h4>
                                            <Link
                                                to={
                                                    process.env.PUBLIC_URL +
                                                    "/product/" +
                                                    item.id
                                                }
                                            >
                                                {" "}
                                                {getLangValue(
                                                    item,
                                                    "product_name"
                                                )}{" "}
                                            </Link>
                                        </h4>
                                        <h6>
                                            {t("nav_menu__cart_qty", {
                                                qty: item.quantity,
                                            })}
                                        </h6>
                                        <span>
                                            {discountedPrice !== null
                                                ? currency.currencySymbol +
                                                  parseFloat(
                                                      finalDiscountedPrice
                                                  ).toLocaleString(undefined, {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                  })
                                                : currency.currencySymbol +
                                                  parseFloat(
                                                      finalProductPrice
                                                  ).toLocaleString(undefined, {
                                                      minimumFractionDigits: 2,
                                                      maximumFractionDigits: 2,
                                                  })}
                                        </span>
                                        {item.selectedProductColor &&
                                        item.selectedProductSize ? (
                                            <div className="cart-item-variation">
                                                <span>
                                                    Color:{" "}
                                                    {item.selectedProductColor}
                                                </span>
                                                <span>
                                                    Size:{" "}
                                                    {item.selectedProductSize}
                                                </span>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                        {getLangValue(
                                            item,
                                            "variant_value_1"
                                        ) ||
                                        getLangValue(item, "variant_value_2") ||
                                        getLangValue(
                                            item,
                                            "variant_value_3"
                                        ) ? (
                                            <div className="cart-item-variation">
                                                {getLangValue(
                                                    item,
                                                    "variant_value_1"
                                                ) && (
                                                    <span>
                                                        {getLangValue(
                                                            item,
                                                            "variant_name_1"
                                                        )}
                                                        :{" "}
                                                        {getLangValue(
                                                            item,
                                                            "variant_value_1"
                                                        )}
                                                    </span>
                                                )}
                                                {getLangValue(
                                                    item,
                                                    "variant_value_2"
                                                ) && (
                                                    <span>
                                                        {getLangValue(
                                                            item,
                                                            "variant_name_2"
                                                        )}
                                                        :{" "}
                                                        {getLangValue(
                                                            item,
                                                            "variant_value_2"
                                                        )}
                                                    </span>
                                                )}

                                                {getLangValue(
                                                    item,
                                                    "variant_value_3"
                                                ) && (
                                                    <span>
                                                        {getLangValue(
                                                            item,
                                                            "variant_name_3"
                                                        )}
                                                        :{" "}
                                                        {getLangValue(
                                                            item,
                                                            "variant_value_3"
                                                        )}
                                                    </span>
                                                )}
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className="shopping-cart-delete">
                                        <button
                                            onClick={() => {
                                                if (item.is_free_product == 1)
                                                    return;
                                                dispatch(removeFromCart(item));
                                            }}
                                        >
                                            <i className="fa fa-times-circle" />
                                        </button>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                    <div className="shopping-cart-total">
                        <h4>
                            {t("nav_menu__cart_coupon_label")}
                            <span className="shop-total">
                                -
                                {currency.currencySymbol +
                                    coupon_discount.toFixed(2)}
                            </span>
                        </h4>
                    </div>
                    <div className="shopping-cart-total">
                        <h4>
                            {t("nav_menu__cart_credit_label")}
                            <span className="shop-total">
                                -
                                {currency.currencySymbol +
                                    credit_discount.toFixed(2)}
                            </span>
                        </h4>
                    </div>
                    <div className="shopping-cart-total">
                        <h4>
                            {t("nav_menu__cart_shipping_label")}
                            <span className="shop-total">
                                {deliveryCountryId
                                    ? currency.currencySymbol +
                                      parseFloat(shipping_fee).toFixed(2)
                                    : t("cart__calculated_at_checkout")}
                            </span>
                        </h4>
                    </div>
                    <div className="shopping-cart-total">
                        <h4>
                            {t("nav_menu__cart_total_label")}
                            <span className="shop-total">
                                {currency.currencySymbol + total.toFixed(2)}
                            </span>
                        </h4>
                    </div>
                    <div className="shopping-cart-btn btn-hover text-center">
                        <Link
                            className="default-btn"
                            to={process.env.PUBLIC_URL + "/cart"}
                        >
                            {t("nav_menu__cart_view_cart")}
                        </Link>
                        <Link
                            className="default-btn"
                            to={process.env.PUBLIC_URL + "/login-register?checkout=1"}
                        >
                            {t("nav_menu__cart_checkout")}
                        </Link>
                    </div>
                </Fragment>
            ) : (
                <p className="text-center">{t("nav_menu__cart_empty_item")}</p>
            )}
        </div>
    );
});

export default MenuCart;
