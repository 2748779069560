import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ShopSearch = () => {
    const navigate = useNavigate();
    const { category_id } = useParams();
    const location = useLocation();
    const {t} = useTranslation();
    const [keyword, setKeyword] = useState("");

    const handleSearch = (e) => {
        e.preventDefault();
        if (category_id) {
            navigate(
                process.env.PUBLIC_URL +
                    `/shop/${category_id}?keyword=${keyword}`
            );
        } else {
            navigate(process.env.PUBLIC_URL + `/shop?keyword=${keyword}`);
        }
    };

    useEffect(() => {
        setKeyword("");
    }, [category_id]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const keywordFromURL = searchParams.get("keyword");
        if (keywordFromURL) {
            setKeyword(keywordFromURL);
        }
    }, [category_id, location.search]);

    return (
        <div className="sidebar-widget">
            <h4 className="pro-sidebar-title">{t("shop__search_label")} </h4>
            <div className="pro-sidebar-search mb-50 mt-25">
                <form className="pro-sidebar-search-form" action="#">
                    <input
                        type="text"
                        placeholder={t("shop__search_placeholder")}
                        value={keyword}
                        onChange={(e) => setKeyword(e.target.value)}
                    />
                    <button onClick={handleSearch}>
                        <i className="pe-7s-search" />
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ShopSearch;
