import axios from "axios";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CLIENT_ID } from "../../../helpers/config";
import { handleErrorRes, toastSuccess } from "../../../helpers/utils";
import { useFormik } from "formik";
import * as Yup from "yup";

const SubscribeEmailV2 = ({}) => {
    const { t } = useTranslation();
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(t("common__email_validation"))
            .required(t("common__required_field")),
    });

    const formik = useFormik({
        validateOnChange: false,
        initialValues: {
            email: "",
        },
        validationSchema,
        onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
            try {
                setSubmitting(true);
                await subscribeNewsletter(values);
                resetForm();
            } catch (err) {
                console.log(err);
                setSubmitting(false);
            }
        },
    });

    const { handleSubmit, values, handleChange, isSubmitting, setFieldValue } =
        formik;

    const subscribeNewsletter = async (values) => {
        let subscribeResponse = await axios.post(
            process.env.REACT_APP_API_URL + "/newsletter_subscribe.php",
            {
                ...values,
                client_id: CLIENT_ID,
            }
        );

        let data = handleErrorRes(subscribeResponse, false);
        if (data.status == 1) {
            toastSuccess(data);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="subscribe-form" style={{width:'90%'}}>
                <div className="mc-form d-flex aling-items-center gap-2">
                    <div className="flex-grow-1">
                        <input
                            id="email"
                            className="email"
                            type="email"
                            placeholder={t("form__email_placeholder")}
                            value={values.email}
                            onChange={handleChange}
                        />
                        {formik.errors.email ? (
                            <div className="text-danger">
                                {formik.errors.email}
                            </div>
                        ) : null}
                    </div>
                    <div className="clear flex-shrink-0">
                        <button
                            className="button"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {t("footer__subscribe")}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default SubscribeEmailV2;
