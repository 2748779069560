import axios from "axios";
import { CLIENT_ID } from "../../helpers/config";
import { handleErrorRes } from "../../helpers/utils";
import { logoutUser } from "./user-slice";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const orderSlice = createSlice({
    name: "order",
    initialState: {
        orders: [],
        total_orders: 0,
        orders_status: "idle",
        purchased_items: [],
        total_purchased_items: 0,
        purchased_items_status: "idle",
        vouchers: [],
        total_vouchers: 0,
        vouchers_status: "idle",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchOrders.pending, (state, action) => {
            state.orders_status = "loading";
        });
        builder.addCase(fetchOrders.fulfilled, (state, action) => {
            state.orders_status = "idle";
            state.orders = action.payload.orders;
            state.total_orders = action.payload.total_rows || 0;
        });
        builder.addCase(fetchPurchasedItems.pending, (state, action) => {
            state.purchased_items_status = "loading";
        });
        builder.addCase(fetchPurchasedItems.fulfilled, (state, action) => {
            state.purchased_items_status = "idle";
            state.purchased_items = action.payload.order_products;
            state.total_purchased_items = action.payload.total_rows || 0;
        });
        builder.addCase(fetchVouchers.pending, (state, action) => {
            state.vouchers_status = "loading";
        });
        builder.addCase(fetchVouchers.fulfilled, (state, action) => {
            state.vouchers_status = "idle";
            state.vouchers = action.payload.vouchers;
            state.total_vouchers = action.payload.total_rows || 0;
        });
    },
});

export const fetchOrders = createAsyncThunk(
    "order/fetchOrders",
    async ({ limit, offset }, { getState, dispatch, rejectWithValue }) => {
        try {
            const userState = getState().user;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/order_get.php",
                {
                    limit,
                    offset,
                    client_id: CLIENT_ID,
                    access_token: userState.site_access_token || "",
                },
                {
                    headers: {
                        Authorization: "Bearer " + userState.token,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchPurchasedItems = createAsyncThunk(
    "order/fetchPurchasedItems",
    async ({ limit, offset }, { getState, dispatch, rejectWithValue }) => {
        try {
            const userState = getState().user;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/purchased_item_get.php",
                {
                    limit,
                    offset,
                    client_id: CLIENT_ID,
                    access_token: userState.site_access_token || "",
                },
                {
                    headers: {
                        Authorization: "Bearer " + userState.token,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

export const fetchVouchers = createAsyncThunk(
    "order/fetchVouchers",
    async ({ limit, offset, status }, { getState, dispatch, rejectWithValue }) => {
        try {
            const userState = getState().user;
            const response = await axios.post(
                process.env.REACT_APP_API_URL + "/member_voucher_get.php",
                {
                    limit,
                    offset,
                    status,
                    client_id: CLIENT_ID,
                    access_token: userState.site_access_token || "",
                },
                {
                    headers: {
                        Authorization: "Bearer " + userState.token,
                    },
                }
            );

            return handleErrorRes(response);
        } catch (error) {
            // Check if the error response status is 401
            if (error.response.status === 401) {
                dispatch(logoutUser());
            }
            return rejectWithValue(error.message);
        }
    }
);

// export const {  } = orderSlice.actions;
export default orderSlice.reducer;
